import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { addNoteToggle, addPatientProgressNote, getProgressNoteSubject } from "../../redux/addNoteSlice";
import { InputTextarea } from "primereact/inputtextarea";
import { Modal, ModalBody, ModalHeader, Spinner, Toast } from "reactstrap";
import { displayToast } from "../../components/Toast/Toast";
import { fetchAll, messageList, messageList2 } from "../../redux/patientSlice";

export const AddNote = ({ userEmail, firstName, lastName, mrn }) => {
    const { isNote, subjectItem, noteItem, isAddNote } = useSelector((state) => state.note);
    const dispatch = useDispatch();



    const [selectedItem, setSelectedItem] = useState(null);
    const [note, setNote] = useState('');

    useEffect(() => {
        if (isNote) {

            dispatch(getProgressNoteSubject());
            // dispatch(
            //     messageList({
            //         mrn: String(noteItem.mrn),
            //         usermail: userEmail,
            //     })
            // )


        }
    }, [isNote, dispatch]);

    useEffect(() => {
        if (noteItem) {
            setNote(noteItem.message_text || '');
            setSelectedItem(noteItem.subject || null);
        } else {
            resetFields();
        }
    }, [noteItem]);


    const toggle = (rowData) => {
        dispatch(addNoteToggle({ isOpen: !isNote, rowData })); // Toggle modal open/close state
        resetFields(); // Reset fields when closing the modal
    };

    const dropdownOptions = subjectItem ? subjectItem?.map((item) => ({
        label: item.texT_,
        value: item.texT_
    })) : [];

    const resetFields = () => {
        setSelectedItem(null);
        setNote('');
    };



    const save = async () => {

        try {
            // if (!noteItem) {
            //     displayToast({ msg: "Note item is required", type: "error" });
            //     return;
            // }

            const data = {
                cMrn: String(noteItem.mrn || noteItem.patient_id || ""),
                cSubject: selectedItem || "",
                cBody: note,
                firstName,
                lastName,
                email: userEmail,
                message_id: noteItem?.message_id || undefined,
                patientNotesID: noteItem?.message_id || undefined,
            };


            await dispatch(addPatientProgressNote({
                data, userEmail,
                callback: (res) => {
                    if (res?.data?.success) {
                        displayToast({ msg: "Note Added Successfully", type: "success" });
                        toggle(noteItem);
                    } else {
                        displayToast({ msg: res?.data?.message || "Something went wrong", type: "error" });
                    }
                }
            }));
            dispatch(
                messageList2({
                    mrn: `${mrn}`,
                    usermail: userEmail,
                })
            );
        } catch (error) {
            console.error("Error Adding Notes:", error);
        }
    };

    return (
        <>
            <Toast />
            <div className="card flex justify-content-center border-0">
                <Modal className="referral-modal1" isOpen={isNote} toggle={() => toggle(noteItem)} centered backdrop={"static"}>
                    <ModalHeader className="w-100 align-items-start pb-2" toggle={() => toggle(noteItem)}>
                        Add to Progress Notes
                    </ModalHeader>
                    <ModalBody>
                        <form className="custom-form email-form physician-form">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label htmlFor="subjectDropdown">Subject</label>
                                </div>
                                <div className="col-md-9">
                                    <Dropdown
                                        id="subjectDropdown"
                                        value={selectedItem}
                                        onChange={(e) => setSelectedItem(e.value)}
                                        options={dropdownOptions}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        placeholder="Select Subject"
                                        className="w-100 form-control"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label htmlFor="noteDetails">Note Details</label>
                                </div>
                                <div className="col-md-9">
                                    <InputTextarea
                                        id="noteDetails"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        className="w-100 form-control custom-textarea"
                                    />
                                </div>
                            </div>
                            <div className="d-flex mt-2 border-top pt-3 mt-3 justify-content-end">
                                <button
                                    type="button"
                                    className={`custom-button me-2 ${!selectedItem || isAddNote ? "disabled" : ""}`}
                                    onClick={save}
                                    disabled={!selectedItem || isAddNote}
                                >
                                    Save
                                    {isAddNote && <Spinner className="ms-2" size="sm" color="light" />}
                                </button>
                                <button type="button" className="common-btn" onClick={() => toggle(noteItem)}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};