import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";

export const referralfetchAll = createAsyncThunk("referralfetchAll", async (userEmail) => {
  try {
    if (userEmail == null) {
      return {
        data: [],
        isUserPermission: false,
        isUserExpAuthPermission: false,
      };
    }
    const response = await api.get(`/api/PhysicianPortal/GetPhysicianReferralData/${userEmail}`);
    return {
      data: response.data.data,
      isUserPermission: response.data.permission.isUserPermission,
      isUserExpAuthPermission: response.data.permission.isUserExpAuthPermission,
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const getSites = createAsyncThunk("getSites", async () => {
  try {
    const response = await api.get(`/api/PhysicianPortal/GetSites`);
    const decodedData = atob(response.data);
    const jsonData = JSON.parse(decodedData);
    return {
      data: jsonData.Data
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});
export const patientfetchdata = createAsyncThunk("patientfetchdata", async (userEmail) => {
  try {
    if (userEmail == null) {
      return {
        data: [],
      };
    }
    const response = await api.get(`/api/PhysicianPortal/GetPhysicianReferralData/${userEmail}`);
    return {
      data: response.data.data,
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const toggleModal = createAsyncThunk(
  "toggleModal",
  async ({ isOpen, id, rowData }) => {
    return {
      isOpen: isOpen,
      id: id,
      rowData: rowData,
    };
  }
);

export const mrnModal = createAsyncThunk(
  "mrnModal",
  async ({ isOpen, rowData }) => {
    return {
      isOpen: isOpen,
      rowData: rowData
    };
  }
);

export const addDocumentModal = createAsyncThunk(
  "addDocumentModal",
  async ({ isOpen, rowData }) => {
    return {
      isAddDoucmentOpen: isOpen,
      rowData: rowData
    };
  }
);

export const editDocumentModal = createAsyncThunk(
  "editDocumentModal",
  async ({ isOpen, rowData }) => {
    return {
      isEditOpen: isOpen,
      rowData: rowData
    };
  }
);

export const existsPatientToggle = createAsyncThunk(
  "existsPatientToggle",
  async ({ isOpen }) => {
    return {
      isExitPatientOpen: isOpen,
    };
  }
);

export const reviewToggle = createAsyncThunk(
  "reviewToggle",
  async ({ isOpen }) => {
    return {
      isReview: isOpen,
    };
  }
);

export const createMrn = createAsyncThunk(
  "createMrn",
  async ({ mrnData, callback }, thunkApi) => {
    try {
      var newData = {
        loginEmail: mrnData?.loginEmail,
        zip: mrnData?.zip,
        referral_id: mrnData?.referral_id,
        siteno: mrnData?.siteno,
        ssn: mrnData?.ssn,
        phone: mrnData?.phone,
        dob: mrnData?.dob,
        lasT_NAME: mrnData?.lasT_NAME,
        firsT_NAME: mrnData?.firsT_NAME,
      };
      const response = await api.post(`/api/PhysicianPortal/AddNewPatientFromReferral`, newData);
      if (callback && response.data.success) {
        callback(response)
      }
      await thunkApi.dispatch(referralfetchAll(mrnData?.loginEmail));
      return {
        message: response.data.message,
        data: response.data
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);

export const updateReferralStatus = createAsyncThunk(
  "updateReferralStatus",
  async ({ referral_id, status, email }) => {
    try {
      var newData = {
        referral_id: referral_id,
        status: status,
        email: email,
      };
      const response = await api.post(`/api/PhysicianPortal/updateReferralStatus`, newData);
      return {
        referral_id: referral_id,
        status: status,
        email: email,
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);

export const fileDownload = createAsyncThunk(
  "fileDownload",
  async (filename) => {
    try {
      const newData = {
        fileName: filename,
      };
      const response = await api.post("/api/ExpiringAuth/DownloadFile", newData, {
        responseType: "blob"
      });
      return response;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

const referralSlice = createSlice({
  name: "referrals",
  initialState: {
    loading: false,
    modalLoading: false,
    mrnLoading: false,
    item: [],
    isOpen: false,
    isMrnOpen: false,
    isEditOpen: false,
    rowData: null,
    isUserExpAuthPermission: false,
    patientData: [],
    isAddDoucmentOpen: false,
    isDocumentView: false,
    isExitPatientOpen: false,
    site: [],
    documentItem: [],
    referralItem: [],
    patientData: null,
    patientMNR: null,
    isReview: false,
    isconfirm: false,
    isconfirmLoading: false
  },
  reducers: {
    toggleViewModal(state, action) {
      state.isDocumentView = action.payload.isOpen;
      state.documentItem = action.payload.rowData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(referralfetchAll.fulfilled, (state, action) => {
      state.isUserExpAuthPermission = action.payload.isUserExpAuthPermission;
      const newData = action.payload.data?.map((val) => {
        return {
          ...val,
          site: val?.site === null ? "" : val?.site,
          category: val?.category === null ? "" : val?.category,
        }
      });
      state.item = newData;
      state.loading = false;
    });

    builder.addCase(getSites.fulfilled, (state, action) => {
      state.site = action.payload.data;
      state.loading = false;
    });
    builder.addCase(referralfetchAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(referralfetchAll.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(patientfetchdata.fulfilled, (state, action) => {
      state.patientData = action.payload;
      state.loading = false;
    });
    builder.addCase(patientfetchdata.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patientfetchdata.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(toggleModal.fulfilled, (state, action) => {
      state.isOpen = action.payload.isOpen;
    })
    builder.addCase(mrnModal.fulfilled, (state, action) => {
      state.isMrnOpen = action.payload.isOpen;
      state.referralItem = action.payload.rowData;
    })
    builder.addCase(existsPatientToggle.fulfilled, (state, action) => {
      state.isExitPatientOpen = action.payload.isExitPatientOpen;
    })
    builder.addCase(reviewToggle.pending, (state, action) => {
      state.isconfirmLoading = true;
    })
    builder.addCase(reviewToggle.fulfilled, (state, action) => {
      state.isReview = action.payload.isReview;
      state.isconfirmLoading = false;
    })
    builder.addCase(addDocumentModal.fulfilled, (state, action) => {
      state.isAddDoucmentOpen = action.payload.isAddDoucmentOpen;
      if (action.payload.message === "patient already exists." || action.payload.message === "SSN already exists.") {
        state.patientMNR = action.payload.data
      }
      state.documentItem = action.payload.rowData;
    })
    builder.addCase(editDocumentModal.fulfilled, (state, action) => {
      state.isEditOpen = action.payload.isEditOpen;
      state.documentItem = action.payload.rowData;
    })
    builder.addCase(createMrn.pending, (state, action) => {
      state.isOpen = true;
      state.modalLoading = true;
    });
    builder.addCase(createMrn.fulfilled, (state, action) => {
      if (action.payload.message === "patient already exists." || action.payload.message === "SSN already exists.") {
        state.isExitPatientOpen = true;
        state.patientMNR = action.payload.data
      }
      state.patientData = action.payload.data;
      state.isOpen = false;
      state.modalLoading = false;
    });
    builder.addCase(updateReferralStatus.fulfilled, (state, action) => {
      const index = state.item.findIndex(item => item.referral_id === action.payload.referral_id);
      if (index !== -1) {
        if (state.item[index].mrn === "Pending") {
          state.item[index].mrn = "Review";
        }
        state.item[index] = {
          ...state.item[index],
          ...action.payload,
        };
      } else {
        console.log(`Item with referral_id ${action.payload.referral_id} not found.`);
      }
    });
  },
});

export const { toggleViewModal } = referralSlice.actions;
export default referralSlice.reducer;
