import React from 'react';
import MultiSelect from '../../components/Filter/FilterDropdown';

const AdvancedFilterPrescriber = ({
    filterOptions,
    data,
    globalFilterOptions,
    setGlobalFilterOptions,
}) => {

    return (
        <div className="advance-filter">
            <div className="fadein animation-duration-500 box">
                <div className={`show-filterlist start-0 end-0 w-100 d-flex align-items-center flex-wrap show`}>
                    {filterOptions?.map(({ globalFilterName, apiFieldName, placeholder, isBlankField }) => {
                        let options = [];

                        if (data) {
                            options = data.reduce((acc, item) => {
                                const fieldValue = item[apiFieldName];

                                if (Array.isArray(fieldValue)) {
                                    fieldValue?.forEach(value => {
                                        if (!acc?.some(option => option.label === value)) {
                                            acc?.push({ label: value, value: value });
                                        }
                                    });
                                } else if (fieldValue) {
                                    if (!acc?.some(option => option.label === fieldValue)) {
                                        acc?.push({ label: fieldValue, value: fieldValue });
                                    }
                                }
                                return acc;
                            }, [])?.sort((a, b) => a.label.localeCompare(b.label));

                            if (isBlankField) {
                                options?.unshift({ label: "(Blank)", value: null || "" });
                            }
                        }

                        return (
                            <MultiSelect
                                key={globalFilterName}
                                value={globalFilterOptions[globalFilterName]}
                                options={options}
                                onChange={(e) => {
                                    setGlobalFilterOptions((prev) => ({
                                        ...prev,
                                        [globalFilterName]: e.value,
                                    }));
                                }}
                                placeholder={placeholder}
                            />
                        );
                    })}

                </div>
            </div>
        </div>
    );
};

export default AdvancedFilterPrescriber;