import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utility/api";
import axios from "../utility/api";

export const fetchAll = createAsyncThunk("fetchAll", async () => {
  try {
    const response = await api.get(`api/Patient/patient`);
    return {
      data: response.data.data,
      isUserPermission: response.data.permission.isUserPermission,
    };
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const fetchAllNogo = createAsyncThunk("fetchAllNogo", async () => {
  try {
    const response = await api.get(`/api/Patient/NoGoPatient`);
    return {
      nodata: response.data.data,
      isUserPermission: response.data.permission,
    };

  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const patientExpiringAuthPermission = createAsyncThunk("patientExpiringAuthPermission", async (userEmail) => {
  try {

    const response = await api.get(`api/Patient/PatientExpiringAuthPermission`);
    return response.data;
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const fetchById = createAsyncThunk("fetchById", async (id) => {
  try {
    if (id) {
      const responseid = await api.get(`api/Patient/task/${id}`);
      const taskdata = responseid.data;
      return taskdata.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const fetchDocument = createAsyncThunk("fetchDocument", async (id) => {
  try {
    if (id) {
      const documentid = await api.get(`/api/Patient/patientDocuments/${id}`);
      const taskdata = documentid.data;
      return taskdata.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching patient data:", error);
    throw error;
  }
});

export const updateData = createAsyncThunk(
  "updateData",
  async ({
    patientTasksStatusDTOs,
    email,
    name,
    category,
    payer,
    patient_state,
    prescriber_state,
    patient_sub_status_id,
    mrn,
    status_id,
    follow_up_by_role,
  }, thunkApi) => {
    try {
      const taskdataWithSerializedDates = patientTasksStatusDTOs?.map((item) => ({
        ...item,
        modified_date: isDateObject(item?.modified_date)
          ? item?.modified_date.toISOString()
          : null,
      }));

      const newData2 = taskdataWithSerializedDates?.map((item) => {
        if (item?.task_id === 4) {
          const newList = [...item?.childList];
          const mangecare = newList[0]?.is_current;
          const nutritionReviewChecked = newList[1]?.is_current;
          const prgmMgmtChecked = newList[2]?.is_current;
          const newChecked = mangecare && nutritionReviewChecked && prgmMgmtChecked;

          return {
            ...item,
            is_current: newChecked ? true : false,
          }
        } else if (item?.task_id === 5) {
          const newList = [...item?.childList];
          const mangecare = newList[0]?.is_current;
          const nutritionReviewChecked = newList[1]?.is_current;
          const prgmMgmtChecked = newList[2]?.is_current;
          const newChecked = mangecare || nutritionReviewChecked || prgmMgmtChecked;

          return {
            ...item,
            is_current: newChecked ? true : false,
          }
        } else if (item?.task_id === 9) {
          const newList = [...item?.childList];
          const mangecare = newList[0]?.is_current;
          const nutritionReviewChecked = newList[1]?.is_current;
          const prgmMgmtChecked = newList[2]?.is_current;
          const newChecked = mangecare || nutritionReviewChecked || prgmMgmtChecked;

          return {
            ...item,
            is_current: newChecked ? true : false,
          }
        } else {
          return item;
        }
      });
      const manageCareReviewChecked2 = newData2?.find(item => item?.task_id === 9)?.childList[0]?.is_current
      const nutritionReviewChecked2 = newData2?.find(item => item?.task_id === 9)?.childList[1]?.is_current
      const prgmMgmtChecked2 = newData2?.find(item => item?.task_id === 9)?.childList[2]?.is_current

      var newData = {
        patientTasksStatusDTOs: newData2,
        email,
        manageCareReviewChecked: manageCareReviewChecked2,
        nutritionReviewChecked: nutritionReviewChecked2,
        prgmMgmtChecked: prgmMgmtChecked2,
        name,
        category,
        payer,
        patient_state,
        prescriber_state,
        patient_sub_status_id,
        mrn,
        status_id,
        follow_up_by_role,
      };
      const response = await api.post(`api/Patient`, newData);
      await thunkApi.dispatch(fetchAll());
      return {
        taskdata: newData2,
      };
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
);

const isDateObject = (value) => value instanceof Date && !isNaN(value);

function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
};

export const updateChatModalData = createAsyncThunk(
  "updateChatModalData",
  async (messagedata, thunkApi) => {
    try {
      const response = await api.post(`/api/Patient/addpatientmassages`, {
        mrn: messagedata.mrn?.toString(),
        message: messagedata?.message,
        usermail: messagedata.usermail,
        employee_name: messagedata.employee_name,
        message_type_id: messagedata.message_type_id
      });
      if (response.data.success) {
        thunkApi.dispatch(
          messageList2({
            mrn: `${messagedata?.mrn}`,
            usermail: messagedata?.usermail,
          })
        );
      }
      const newData = {
        message_text: messagedata?.message,
        employee_email: messagedata?.usermail,
        mrn: messagedata?.mrn,
        employee_name: messagedata.employee_name,
        message_type_id: messagedata?.message_type_id,
        created_date: convertTZ(new Date().toISOString(), "America/New_York"),
      };
      return newData;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const messageList = createAsyncThunk(
  "messageList",
  async (requestData) => {
    try {
      const response = await api.post("api/Patient/chat", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      return data.data;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);
export const messageList2 = createAsyncThunk(
  "messageList2",
  async (requestData) => {
    try {
      const response = await api.post("api/Patient/chat", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      return data.data;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);
export const toggleModal = createAsyncThunk(
  "toggleModal",
  async ({ isOpen, id, rowData }) => {
    return {
      isOpen: isOpen,
      id: id,
      rowData: rowData,
    };
  }
);

export const documentmodal = createAsyncThunk(
  "documentmodal",
  async ({ isOpen, id, rowData }) => {
    return {
      isDocumentOpen: isOpen,
      documentid: id,
      rowData: rowData,
    };
  }
);

export const pdfModal = createAsyncThunk(
  "pdfModal",
  async ({ isOpen }) => {
    return {
      isPdfOpen: isOpen,
    };
  }
);

export const toggleChatModal = createAsyncThunk(
  "toggleChatModal",
  async ({ isChatModalOpen, id, rowData }) => {
    return {
      isChatModalOpen: isChatModalOpen,
      id: id,
      rowData: rowData,
    };
  }
);


export const uploadFileForChat = createAsyncThunk(
  "uploadFileForChat",
  async ({ formData, data }, thunkApi) => {
    try {
      const response = await api.post("/api/Patient/UploadFileForPatientChat", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const newData = {
        message_text: response.data?.data,
        employee_email: data?.usermail,
        mrn: data?.mrn,
        employee_name: data.employee_name,
        message_type_id: 1,
        created_date: convertTZ(new Date().toISOString(), "America/New_York"),
      };

      thunkApi.dispatch(
        messageList2({
          mrn: `${data?.mrn}`,
          usermail: data?.usermail,
        })
      )

      return newData;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);


export const chatfileDownload = createAsyncThunk(
  "chatfileDownload",
  async (filename) => {
    try {
      const newData = {
        fileName: filename,
      };
      const response = await axios.post("/api/Patient/DownloadFile", newData, {
        responseType: "blob"
      });
      return response;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const getStatusDescription = createAsyncThunk(
  "getStatusDescription",
  async () => {
    try {
      const response = await axios.get("/api/Patient/GetStatusDescription");
      return response.data?.data;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const getRoleNames = createAsyncThunk(
  "getRoleNames",
  async () => {
    try {
      const response = await axios.get("/api/Patient/GetRoleNames");
      return response.data?.data;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const updatePendingStatus = createAsyncThunk(
  "updatePendingStatus",
  async ({ data }, thunkApi) => {
    try {
      const response = await axios.post("/api/Patient/AddUpdatePatientSubStatus", data);
      await thunkApi.dispatch(fetchAll());
      return response;
    } catch (error) {
      console.error("Error fetching messages:", error);
      throw error;
    }
  }
);

export const addUpdatePatientPinRequest = createAsyncThunk(
  "addUpdatePatientPinRequest",
  async ({ ops_order_id, mrn, is_flag, created_by }, thunkApi) => {
    try {
      const data = {
        ops_order_id: ops_order_id,
        mrn: mrn,
        is_flag: is_flag,
        created_by: created_by,
      }
      const response = await axios.post("/api/Patient/AddUpdatePatientPinRequest", data);
      if (response.status === 200) {
        await thunkApi.dispatch(fetchAll());
      }
    } catch (error) {
      console.error("Error while updateing pin patient :", error.message);
      throw error;
    }
  }
);

export const savePatientDocument = createAsyncThunk(
  "savePatientDocument",
  async ({ data }, thunkApi) => {
    try {
      const response = await axios.post("/api/Patient/SavePatientDocument", data);
    } catch (error) {
      console.error("Error while updateing pin patient :", error.message);
      throw error;
    }
  }
);

export const uploadPatientFile = createAsyncThunk(
  "uploadPatientFile",
  async ({ data }, thunkApi) => {
    try {
      const response = await axios.post("/api/AzureFileShare/uploadPatientFile", data);

      console.log("response", response);

      return response.data.message;
    } catch (error) {
      console.error("Error while updateing pin patient :", error.message);
      throw new Error(error);
    }
  }
);

// Create the patient slice
const patientSlice = createSlice({
  name: "patient",
  initialState: {
    loading: false,
    stepLoading: true,
    modalLoading: false,
    documentLoading: false,
    modalError: null,
    error: null,
    documentError: null,
    list: [],
    documentList: [],
    reference_date: null,
    isOpen: false,
    isChatModalOpen: false,
    isDocumentOpen: false,
    isPdfOpen: false,
    modalOpenID: null,
    documentOpenID: null,
    items: [],
    role: [],
    nongoItem: [],
    pendingStatusModal: {
      isOpen: false,
      data: undefined,
    },
    permission: {
      isUserPermission: false,
      isUserExpAuthPermission: false,
      isUserNOGOPermission: false,
    },
    statusDescription: [],
    roleNames: [],

    chatModalData: {
      mrn: null,
      message: "",
      email: "",
      previousMessages: [],
    },
    message: {
      marn: null,
      data: [],
    },
    chatMessages: {
      mrnnumber: null,
    },
    messageList: [],

  },
  reducers: {
    togglePendingStatusModal(state, action) {
      state.pendingStatusModal.isOpen = action.payload?.isOpen;
      state.pendingStatusModal.data = action.payload?.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {

      state.isUserPermission = action.payload.isUserPermission;
      const newData = action?.payload?.data?.map((val) => {
        return {
          ...val,
          site: val?.site === null ? "" : val?.site,
          assigned_to: val?.assigned_to === null ? "" : val?.assigned_to,
          intake: val?.intake === null ? "" : val?.intake,
          case_manager: val?.case_manager === null ? "" : val?.case_manager,
          pat_category: val?.pat_category === null ? "" : val?.pat_category,
          prescriber_name: val?.prescriber_name === null ? "" : val?.prescriber_name,
          next_action_by: val?.next_action_by === null ? "" : val?.next_action_by,
          nps_mcr_uc_string: val?.nps_mcr_uc?.join(","),
        }
      });
      state.items = newData;
      state.loading = false;
      state.stepLoading = false;
    }).addCase(fetchAll.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchAllNogo.fulfilled, (state, action) => {
        state.permission.isUserPermission = action.payload.isUserNOGOPermission;
        const newDataNogo = action.payload.nodata?.map((val) => {
          return {
            ...val,
            site: !val?.site ? "" : val?.site,
            assigned_to: !val?.assigned_to ? "" : val?.assigned_to,
            intake: !val?.intake ? "" : val?.intake,
            case_manager: !val?.case_manager ? "" : val?.case_manager,
            pat_category: !val?.pat_category ? "" : val?.pat_category,
            prescriber_name: !val?.prescriber_name ? "" : val?.prescriber_name,
            nogo_reason: !val?.nogo_reason ? "" : val?.nogo_reason,
            next_action_by: !val?.next_action_by ? "" : val?.next_action_by,
          }
        });
        state.nongoItem = newDataNogo;
        state.loading = false;
        state.stepLoading = false;
      })
      .addCase(fetchAllNogo.pending, (state) => {
        state.loading = true;
      })
      .addCase(patientExpiringAuthPermission.fulfilled, (state, action) => {
        state.permission.isUserNOGOPermission = action.payload.permission.isUserNOGOPermission;
        state.permission.isUserPermission = action.payload.permission.isUserPermission;
        state.permission.isUserExpAuthPermission = action.payload.permission.isUserExpAuthPermission;
        state.role = action.payload.data[0].role;
      })
      .addCase(messageList.fulfilled, (state, action) => {
        const groupedMessages = [];
        let lastDate = null;
        action?.payload?.forEach((message) => {
          const messageDate = `${new Date(message.created_date).getDate()}-${new Date(message.created_date).getMonth() + 1
            }-${new Date(message.created_date).getFullYear()}`;
          if (lastDate != messageDate) {
            groupedMessages?.push({
              isDateChanged: true,
              type: "dateLabel",
              date: message.created_date,
            });
            lastDate = messageDate;
          }
          groupedMessages?.push(message);
        });
        if (action.payload) {
          const { mrn, message_text, created_date } = action.payload;
          state.chatMessages = {
            ...state.chatMessages,
            mrn,
            message_text,
            created_date,
          };
          state.messageList = groupedMessages;
        }
        state.modalLoading = false;
      })
      .addCase(messageList2.fulfilled, (state, action) => {
        const groupedMessages = [];
        let lastDate = null;
        action?.payload?.forEach((message) => {
          const messageDate = `${new Date(message.created_date).getDate()}-${new Date(message.created_date).getMonth() + 1
            }-${new Date(message.created_date).getFullYear()}`;
          if (lastDate != messageDate) {
            groupedMessages?.push({
              isDateChanged: true,
              type: "dateLabel",
              date: message.created_date,
            });
            lastDate = messageDate;
          }
          groupedMessages?.push(message);
        });
        if (action.payload) {
          const { mrn, message_text, created_date } = action.payload;
          state.chatMessages = {
            ...state.chatMessages,
            mrn,
            message_text,
            created_date,
          };
          state.messageList = groupedMessages;
        }
        state.modalLoading = false;
      })
      .addCase(uploadPatientFile.fulfilled, (state, action) => {
        console.log("action........", action.payload);

        state.pdfDocumentItem = action.payload;
      })
      .addCase(messageList.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(messageList.rejected, (state, action) => {
        state.modalLoading = true;
        state.error = action.error.message;
      })
      .addCase(updateChatModalData.fulfilled, (state, action) => {
        const { mrn, message, usermail, employee_name, message_type_id } = action.payload;
        state.chatMessages = {
          ...state.chatMessages,
          mrn,
          message,
          usermail,
          employee_name,
          message_type_id,
          created_date: new Date().toISOString(),
        };
        state.messageList.push(action.payload);
        const mrnData = state.messageList.map((newmrn) => {
          newmrn.is_read = true;
          return newmrn;
        });
        state.messageList = mrnData;
        state.loading = false;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        const idToUpdate = action.payload?.taskdata[0]?.mrn;
        const updatedItems = state?.items?.map((item) => {
          const mergedCount = action.payload.checkedTasks + action.payload.nonMandatoryTasks;
          if (item?.mrn === idToUpdate) {
            return {
              ...item,
              is_nonmandatory: action.payload.nonMandatoryTasks,
              completed_task_count: mergedCount,
            };
          } else {
            return item;
          }
        });
        state.list = action.payload.taskdata;
        state.isOpen = false;
        state.modalOpenID = null;
        state.loading = false;
      })
      .addCase(toggleModal.fulfilled, (state, action) => {

        state.isOpen = action.payload.isOpen;
        state.modalOpenID = action.payload.id;
        state.rowData = action.payload.rowData;
      })
      .addCase(documentmodal.fulfilled, (state, action) => {

        state.isDocumentOpen = action.payload.isDocumentOpen;
        state.documentOpenID = action.payload.documentid;
        state.rowData = action.payload.rowData;
      })
      .addCase(pdfModal.fulfilled, (state, action) => {
        state.isPdfOpen = action.payload.isPdfOpen;
      })
      .addCase(toggleChatModal.fulfilled, (state, action) => {
        const mrnData = state?.items?.map((newmrn) => {
          if (newmrn.mrn == action.payload.id) {
            newmrn.is_read = true;
          }
          return newmrn;
        });
        state.items = mrnData;
        state.isChatModalOpen = action.payload.isChatModalOpen;
        state.modalOpenID = action.payload.id;
        state.rowData = action.payload.rowData;
      })
      .addCase(fetchById.pending, (state) => {
        state.modalLoading = true;
        state.modalError = null;
      })
      .addCase(fetchById.fulfilled, (state, action) => {
        state.list = action.payload;
        state.modalLoading = false;
        state.modalError = null;
      })
      .addCase(fetchById.rejected, (state, action) => {
        state.modalLoading = false;
        state.modalError = action.error?.message;
      })
      .addCase(fetchDocument.pending, (state) => {
        state.documentLoading = true;
        state.documentError = null;
      })
      .addCase(fetchDocument.fulfilled, (state, action) => {

        state.documentList = action.payload;
        state.documentLoading = false;
        state.documentError = null;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        state.modalLoading = false;
        state.documentError = action.error?.message;
      })
      .addCase(fetchAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addUpdatePatientPinRequest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addUpdatePatientPinRequest.fulfilled, (state, action) => {
      })
      .addCase(addUpdatePatientPinRequest.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadFileForChat.fulfilled, (state, action) => {
        const { mrn, message, usermail, employee_name, message_type_id } = action.payload;
        const mrnData = state.messageList?.map((newmrn) => {
          newmrn.is_read = true;
          return newmrn;
        });
        state.messageList = mrnData;
        state.loading = false;
      })
      .addCase(getStatusDescription.fulfilled, (state, action) => {
        state.statusDescription = action.payload;
      })
      .addCase(getRoleNames.fulfilled, (state, action) => {
        state.roleNames = action.payload;
      });

  },
});

export const { togglePendingStatusModal } = patientSlice.actions;
export default patientSlice.reducer;