import { AutoComplete } from "primereact/autocomplete";
import React, { useState } from "react";

const MrnSearch = ({ data, filters, handleFilterChange }) => {
  const [mrn, setmrn] = useState([]);

  const search = (event) => {
    const query = event.query;
    if (data) {
      const filteredMrns =
        data
          ?.map((rowData) => String(rowData?.mrn))
          ?.filter((mrn) => mrn && mrn?.startsWith(query)) || [];
      setmrn(filteredMrns);
    }
  };

  return (
    <div className="flex justify-content-end position-relative">
      <i className="pi pi-search search-icon position-absolute top-50 translate-middle ps-5 start-0" />
      <AutoComplete
        value={filters?.mrn?.value || ""}
        suggestions={Array.isArray(mrn) ? mrn : []}
        completeMethod={search}
        onChange={(e) => handleFilterChange(e, "mrn")}
        className="form-control suggestion-filter w-100 firstname-input mrn-input"
        placeholder="MRN"
      />
    </div>
  );
};

export default MrnSearch;
