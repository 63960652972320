import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Collapse,
  NavLink,
  NavbarToggler,
} from "reactstrap";
import "../../assets/css/header.css";
import logo from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import newstart from '../../assets/images/new_start.png'
import patient from '../../assets/images/powernewstart.svg'
import powerapps from "../../assets/images/house.svg";
import calender from "../../assets/images/date-expired-icon.svg";
import { useSelector } from "react-redux";
import crm from "../../assets/images/crm.svg";
import sales from "../../assets/images/sales.svg";
import view from "../../assets/images/view.png";
import newrefral from "../../assets/images/newrefral.svg";

function Header({ name }) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const location = useLocation();
  const path = location?.pathname;

  const powerappUrl = process.env.REACT_APP_POWERAPP_URL;

  const isActive = (path) => {
    const fullPath = `${path}`;
    const decodedPathname = decodeURIComponent(location?.pathname);
    return decodedPathname + location?.search === fullPath;
  };

  const { assignRoleByUser } = useSelector((state) => state?.permissions);

  const newstart = assignRoleByUser?.some(role => role.page === "New Starts");

  const sidebarItems = [
    {
      to: "/",
      icon: patient,
      label: "New Starts",
      page: "New Starts",
      section: "All Section"
    },
    {
      to: "/expiring_auth",
      icon: calender,
      label: "Expiring Authorizations",
      page: "Expiring Authorizations",
      section: "All Section"
    },
    {
      to: "/no-go",
      icon: view,
      label: "No-Go's",
      page: "No Go",
      section: "All Section"
    },
    {
      to: `${process.env.REACT_APP_CRM_LINK}`,
      icon: crm,
      label: "CRM",
      page: "CRM",
      section: "All Section"
    },
    {
      to: `${process.env.REACT_APP_SALES_LINK}`,
      icon: sales,
      label: "Sales",
      page: "Sales",
      section: "All Section"
    },
    {
      to: "/new-referrals",
      icon: newrefral,
      label: "New Referrals",
      page: "New Referrals",
      section: "All Section"
    },
  ];

  return (
    <>
      <Navbar expand="lg" className="custom-header py-1">
        <NavbarBrand href="/" className="p-0 m-0">
          <img
            src={logo}
            alt="Logo"
            className="img-fluid"
            style={{ width: "120px" }}
          />
        </NavbarBrand>

        <Nav className="m-auto d-md-flex d-none" navbar>
          <NavItem>
            {path === "/" ? "New Starts" : path === "/expiring_auth" ? "Expiring Authorizations" : path === "/no-go" ? "No Go's" : path === "/new-referrals" ? "New Referrals" : null}
          </NavItem>
        </Nav>

        <NavbarText className="pe-5 ms-0 d-flex align-items-center">
          {name}
        </NavbarText>

        <div className="d-lg-none">
          <NavbarToggler
            onClick={toggleNavbar}
            className="p-0 border-0 mobile-menu"
          />
          <Collapse isOpen={!collapsed} navbar>
            <ul className="menu-list p-0 m-0 mobilemenu">
              <Link
                to={powerappUrl}
                className={isActive(powerappUrl) ? "active" : ""}
              >
                <div className="img-active">
                  <img src={powerapps} alt="Patient" />
                </div>
                <span>Power Apps</span>
              </Link>

              <Link
                to="/unauthorized"
                className="border-0 p-0 m-0"
              >
              </Link>



              {sidebarItems?.map((item) => {
                const isPageExist = assignRoleByUser?.find((state) => state?.page === item?.page);
                if (isPageExist?.read === "Y") {
                  return (
                    <Link
                      to={item?.to}
                      key={item?.label}
                      className={isActive(item?.to) ? "active" : ""}
                    >
                      <div className="img-active">
                        <img
                          className={item?.icon === sales ? "" : "custom-width-height"}
                          src={item?.icon}
                          alt={item?.label}
                        />
                      </div>
                      <span>{item?.label}</span>
                    </Link>
                  )
                }
              })}
            </ul>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default Header;