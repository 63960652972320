import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { addDocumentModal, fileDownload } from '../../redux/referralsSlice';
import download from '../../assets/images/download.svg'
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ArrowUpward, Close } from "@mui/icons-material";
import pdf from '../../assets/images/pdf.svg';
import { editDocument, getAddressInfo, uploadDocument } from '../../redux/physicianSlice';

import { toast as reactToast } from 'react-toastify';


function AddDocumentModal({ userEmail, patientMNR }) {



    const dispatch = useDispatch();
    const { isAddDoucmentOpen, documentItem } = useSelector(state => state.referral);


    const lastItem = patientMNR?.data?.length ? patientMNR.data[patientMNR?.data?.length - 1] : null;





    const toggle = (rowData) => {
        dispatch(addDocumentModal({ isOpen: false, rowData: rowData }));
    };
    useEffect(() => {
        if (isAddDoucmentOpen) {
            dispatch(getAddressInfo());
        }
    }, [isAddDoucmentOpen]);

    const [fileNames, setFileNames] = useState([]);
    const [fileUploadError, setFileUploadError] = useState("");

    const MAX_FILES = 2;
    const FILE_SIZE = 5_000_000;
    const FILE_SUPPORTED_FORMATS = ["pdf"];

    const validationSchema = Yup.object({
        // files: Yup.required("At least one file is required"),
    });


    const initialValues = {
        // name: "",
        // lname: "",
        // date: "",
        files: "",
        // reference: "",
        // site: ""
    };

    const displayToast = ({ msg, type }) => reactToast[type](msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const onSubmit = async (values, { resetForm }) => {

        try {
            const formData = new FormData();

            formData.append("File", values.files[0]);
            formData.append("mrn", lastItem?.mrn);
            formData.append("document_path", lastItem?.document_path);
            formData.append("referral_id", lastItem?.referral_id);
            formData.append("first_name", lastItem.first_name);
            formData.append("last_name", lastItem.last_name);
            formData.append("referral_date", lastItem?.referral_date);
            formData.append("date_of_birth", lastItem?.date_of_birth);
            formData.append("address_1", lastItem?.address_1);
            formData.append("address_type_id", lastItem?.address_type_id);
            formData.append("city_id", lastItem?.city_id);
            formData.append("state_Id", lastItem?.state_id);
            formData.append("zipcode_Id", lastItem?.zipcode);
            formData.append("email", userEmail);
            formData.append("prescriber_id", lastItem?.prescriber_id);
            formData.append("type", lastItem?.status_code);


            dispatch(editDocument({
                formData: formData, callback: (res) => {
                    if (res?.data?.success) {
                        displayToast({ msg: res?.data?.message || "Referral Edited", type: "success" })
                    } else {
                        displayToast({ msg: res?.data?.message || "Something went wrong", type: "error" })
                    }
                }
            }));
            toggle();
            resetForm();
            setFileNames([]);
            setFileUploadError('');
        } catch (error) {
            console.error('Error uploading document:', error);
        }
    };


    const handleFileUpload = (files, setFieldValue) => {
        const invalidFiles = files?.filter(file => {
            const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
            return file?.size > FILE_SIZE || !FILE_SUPPORTED_FORMATS?.includes(fileExtension);
        });

        if (invalidFiles?.length > 0) {
            const sizeError = invalidFiles?.some(file => file?.size > FILE_SIZE);
            const typeError = invalidFiles?.some(file => !FILE_SUPPORTED_FORMATS?.includes(file?.name?.split('.')?.pop()?.toLowerCase()));
            if (sizeError && typeError) {
                setFileUploadError(`Files must be less than ${FILE_SIZE / 1_000_000} MB and in PDF format.`);
            } else if (sizeError) {
                setFileUploadError(`Each file must be less than ${FILE_SIZE / 1_000_000} MB.`);
            } else {
                setFileUploadError(`Only PDF files are allowed.`);
            }
            setFieldValue('files', ''); // Clear file input field
            setFileNames([]); // Clear displayed file names
        } else {
            const filePaths = files?.map(file => file?.name)?.join(',');
            setFileUploadError(''); // Clear any previous errors
            setFieldValue('files', files); // Set the form field value to actual files
            setFileNames(files?.map(file => file?.name)); // Display file names
        }
    };



    const renderError = (message) => <small className="help text-danger m-0">{message}</small>;

    const handleRemoveFile = (index, setFieldValue, values) => {
        const fileNamesArray = values?.files?.split(",");
        fileNamesArray?.splice(index, 1);
        const updatedFiles = fileNamesArray?.join(",");
        setFieldValue("files", updatedFiles);
        setFileNames(fileNamesArray);
    };
    if (patientMNR?.data?.document_path && patientMNR?.data?.document_path !== "Test.pdf") {
        try {
            documentShowing = JSON.parse(patientMNR?.data?.document_path) || [];
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return (
                <p className="text-danger">Unable to load files</p>
            );
        }
    }

    const filepathList = patientMNR?.data?.map((item) => item?.document_path) || [];


    let documentShowing = [];

    // Process filepathList if it's not empty or invalid
    if (filepathList?.length > 0) {
        try {
            documentShowing = filepathList?.map(path => {
                try {
                    return JSON.parse(path) || [];
                } catch (error) {
                    console.error("Error parsing JSON for path:", path, error);
                    return null;
                }
            })?.filter(item => item); // Filter out nulls from parsing errors
        } catch (error) {
            console.error("Error processing filepathList:", error);
            return (
                <p className="text-danger">Unable to load files</p>
            );
        }
    }

    // Flatten and deduplicate staticData
    const staticData = documentShowing
        .flatMap(item => item)
        .filter((value, index, self) =>
            index === self.findIndex((t) => (
                t?.path === value?.path // or t?.documentName === value?.documentName if that's your unique identifier
            ))
        )
        ?.map(doc => ({
            documentName: doc?.file_name,
            path: doc?.path
        })) || [];








    const handleDownload = async (rowData) => {
        const filename = rowData.path;
        const originalName = rowData.documentName;

        try {
            const response = await dispatch(fileDownload(filename));
            const blob = new Blob([response.payload.data], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = originalName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const actionHandler = (rowData) => {
        return (
            <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                    {/* <img src={view} alt='view' onClick={handleView} style={{ cursor: 'pointer', marginRight: '10px' }} /> */}
                    <img src={download} alt='download' onClick={() => handleDownload(rowData)} style={{ cursor: 'pointer' }} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Modal isOpen={isAddDoucmentOpen} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Documents</ModalHeader>

                <ModalBody className='card border-0'>
                    <DataTable value={staticData} className="doucment-table">
                        <Column field="documentName" header="Document Name" sortable></Column>
                        <Column field="dateUploaded" header="Date Uploaded" sortable></Column>
                        <Column header="Action" body={actionHandler}></Column>
                    </DataTable>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            await onSubmit(values, { resetForm });
                            resetForm();
                            setFileNames([]);
                            setFileUploadError("");
                        }}
                        validateOnChange={true}
                        validateOnBlur={true}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className="custom-form email-form physician-form">


                                    <div className="field mb-2">
                                        <div className="control">
                                            <div className="file-upload">

                                                <input
                                                    id="files"
                                                    name="files"
                                                    type="file"
                                                    multiple
                                                    className="custom-upload"
                                                    onChange={(event) => {
                                                        const newFiles = Array.from(event.currentTarget.files);
                                                        if (newFiles?.length > MAX_FILES) {
                                                            setFileUploadError(`You can upload up to ${MAX_FILES} files`);
                                                        } else {
                                                            handleFileUpload(newFiles, setFieldValue);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="files" className="custom-upload-label">
                                                    <ArrowUpward /> Upload Files
                                                </label>
                                                {fileNames?.length > 0 && (
                                                    <div className="file-names custom-file">
                                                        {fileNames?.map((name, index) => (
                                                            <span key={index} className="file-name">
                                                                <img
                                                                    src={pdf}
                                                                    alt="pdf"
                                                                />
                                                                {name}
                                                                <Close
                                                                    onClick={() => handleRemoveFile(index, setFieldValue, values)}
                                                                    style={{ cursor: "pointer", marginLeft: "5px" }}
                                                                />
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {fileUploadError && (
                                                <small className="help text-danger m-0">{fileUploadError}</small>
                                            )}
                                            <ErrorMessage name="files" render={renderError} />
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 border-top pt-3 mt-3 justify-content-end">
                                        <button type="button" className="custom-button me-2" onClick={toggle}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="common-btn ">
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default AddDocumentModal;
