import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import TaskModal from "./taskmodal";
import ChatModal from "./Chat";
import Filterdata from "../../components/Filter/Filterdata";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAll,
  toggleChatModal,
  fetchAllNogo,
  documentmodal,
  togglePendingStatusModal,
  addUpdatePatientPinRequest,
} from "../../redux/patientSlice";
import { Link, useLocation } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Tooltip } from "primereact/tooltip";
import CustomDataTable from "../../components/Filter/CustomDataTable";
import { Badge } from "primereact/badge";
import { ChatBubble } from "@mui/icons-material";
import MrnSearch from "../../components/Filter/MrnSearch";
import FirstName from "../../components/Filter/FirstName";
import { filterOptions, nogoOptions } from "../../components/Filter/NewstartFilterField";
import AdvancedFilter from "../../components/Filter/AdvanceFilter";
import document from "../../assets/images/document.svg"
import DocumentModal from "./DocumentModal";
import { ToastContainer } from "react-toastify";
import PendingStatusModal from "./PendingStatusModal";
import { getRoleNames, getStatusDescription, taskToggle } from "../../redux/taskSlice";
import UpdateTaskModal from "./UpdateTaskModal";
import Pin from "../../assets/images/pin.svg";
import PinFill from "../../assets/images/pin-fill.svg";

const PatientList = ({ userEmail, name }) => {

  const tableData = useSelector((state) => state.patient);

  const { assignRoleByUser } = useSelector((state) => state.permissions)

  const newstart = assignRoleByUser?.some(role =>
    role.page === "New Starts" &&
    role.read === "Y" &&
    (role.role === "Super User" || role.role === "Care Transition Coordinator")
  );
  console.log("newstart", newstart);



  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const [showInput, setShowInput] = useState(true);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [mrn, setmrn] = useState([]);
  const [showAdvancedFilter, setshowAdvancedFilter] = useState(false);
  const [lastReadMessageCount, setLastReadMessageCount] = useState();
  const [lastReadMessageId, setLastReadMessageId] = useState(null);
  const [patCategory, setPatCategory] = useState(null);
  const [payer, setPayer] = useState(null);
  const [userRole, setUserRole] = useState();
  const [loading, setLoading] = useState(false);
  const [referralSource, setReferralSource] = useState(null);
  const [dob, setDob] = useState(null);
  const [fullname, setName] = useState({ firstName: "", lastName: "" });
  const [modalfullname, setModalfullname] = useState(null);
  const [patientState, setPatientState] = useState(null);
  const [prescriberState, setPrescriberState] = useState(null);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [patientmrn, setpatientMrn] = useState(null);
  const [patientfullName, setPatientfullName] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    site: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    intake: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    case_manager: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    assigned_to: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    pat_category: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    prescriber_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    teams: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    patient_status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    is_flag: { value: null, matchMode: FilterMatchMode.EQUALS },
    // nps_mcr_uc_string: { value: [], matchMode: FilterMatchMode.CONTAINS },
    nogo_reason: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nursing_visite_date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [globalFilterOptions, setGlobalFilterOptions] = useState({
    selectedCustomers: [],
    selectedSites: [],
    selectedAssignees: [],
    selectedIntakes: [],
    selectedCaseManagers: [],
    selectedCategories: [],
    selectedPrescribers: [],
    selectedTeams: [],
    selectedStatus: [],
    sitees: [],
    intake: [],
    caseManager: [],
    assignedTo: [],
    categories: [],
    prescriberes: [],
    team: [],
    status: [],
    selectedstatus: [],
    mcReview: [],
    nps_mcr_uc_string: [],
    selectNoGo: [],
    nogo: [],
    nextaction: [],
    seclectNextion: [],
    slectFlag: [],
    flag_val: []
  });

  useEffect(() => {
    setShowInput(path !== "/no-go");
  }, [path]);

  useEffect(() => {
    dispatch(getStatusDescription());
    dispatch(getRoleNames());
  }, []);


  useEffect(() => {
    if (userEmail !== null && userEmail.trim() !== "") {
      setLoading(true);
      try {
        if (path === "/no-go") {
          dispatch(fetchAllNogo());
        } else {
          dispatch(fetchAll());
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  }, [userEmail, path]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (path === "/no-go") {
        dispatch(fetchAllNogo());
      } else {
        dispatch(fetchAll());
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [userEmail, path]);

  const toggleChat = (id, rowData) => {
    dispatch(
      toggleChatModal({ isChatModalOpen: true, id: id, rowData: rowData })
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleAdvancedFilter = () => {
    setshowAdvancedFilter(!showAdvancedFilter);
  };

  const handleClear = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      site: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      intake: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      case_manager: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      assigned_to: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      pat_category: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      prescriber_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      teams: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      patient_status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      // nps_mcr_uc_string: { value: [], matchMode: FilterMatchMode.STARTS_WITH },
      nursing_visite_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    });
    setGlobalFilterValue("");
    setGlobalFilterOptions({
      selectedCustomers: [],
      selectedSites: [],
      selectedAssignees: [],
      selectedIntakes: [],
      selectedCaseManagers: [],
      selectedCategories: [],
      selectedPrescribers: [],
      selectedTeams: [],
      selectedStatus: [],
      sitees: [],
      intake: [],
      caseManager: [],
      assignedTo: [],
      categories: [],
      prescriberes: [],
      team: [],
      status: [],
      selectedstatus: [],
      nps_mcr_uc_string: [],
      selectNoGo: [],
      nogo: []
    });
  };

  const handleFilterChange = (e, field) => {
    let updatedFilters = { ...filters };
    if (
      field === "site" ||
      field === "assigned_to" ||
      field === "intake" ||
      field === "case_manager" ||
      field === "pat_category" ||
      field === "prescriber_name" ||
      field === "teams" ||
      field === "patient_status" ||
      field === "mcR_UC" ||
      field === "nps_mcr_uc_string" ||
      field === "next_action_by" ||
      field === "nogo_reason" ||
      field === "is_flag" ||
      field === "flag_val"
    ) {
      const selectedValues = e?.value || [];

      if (selectedValues?.length === 0) {
        updatedFilters[field] = {
          value: null,
          matchMode: FilterMatchMode.IS,
        };
      }
      if (e.value === "" || e.value === null) {
        updatedFilters[field] = {
          value: null || "",
          matchMode: FilterMatchMode.IS,
        };
      } else {
        updatedFilters[field] = {
          value: e.value,
          matchMode: FilterMatchMode.STARTS_WITH,
        };
      }
    } else if (field === "mrn") {
      updatedFilters[field] = {
        value: e.target.value,
        matchMode: FilterMatchMode.STARTS_WITH,
      };
    }

    if (field === "full_name") {
      const value = e.target.value || "";

      updatedFilters[field] = {
        value: value,
        matchMode: 'contains',
      };
    } else {
      const value = e.target ? e.target.value : "";

      updatedFilters[field] = {
        value: value,
        matchMode: 'contains',
      };
    }
    setFilters(updatedFilters);
  };

  const idTemplate = (rowData) => {
    const completedTask = parseInt(
      (parseInt(rowData?.completed_task_count) * 100) /
      parseInt(rowData?.total_task_count),
      10
    );

    return (
      <>
        <div className="d-flex align-items-center justify-content-center progressbar-task">
          <div
            style={{ width: "40px", cursor: "pointer" }}
            className="d-flex justify-content-between"
          >
            <div
              className="ms-0"
              onClick={() => {
                setPatCategory(rowData?.pat_category)
                setUserRole(rowData?.user_role);
                setName({
                  firstName: rowData?.first_name,
                  lastName: rowData?.last_name,
                });
                setPatientfullName(rowData?.full_name);
                setReferralSource(rowData?.referral_source);
                setDob(rowData?.date_of_birth);
                setPayer(rowData?.payor);
                setPrescriberState(rowData?.prescriber_state);
                setPatientState(rowData?.patient_state);
                setpatientMrn(rowData?.mrn);
                dispatch(taskToggle({ isOpen: true, rowData: rowData }))
              }}
            >
              <CircularProgressbar
                value={completedTask}
                text={`${completedTask}%`}
                strokeWidth={10}
                styles={buildStyles({
                  strokeLinecap: "butt",
                })}
              />
            </div>
          </div>
          <div className="ms-2">
            {rowData?.completed_task_count}/{rowData?.total_task_count}
          </div>
        </div>
      </>
    );
  };

  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  };

  const chatModal = (rowData) => {
    const isPatient = rowData?.is_patient;
    const isRead = rowData?.is_read;
    const islastread = rowData?.last_read_message_id;

    return (
      <div className="p-overlay-badge d-inline-block chat-modal d-flex align-items-center">
        {isPatient === true ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              const formattedName = `${capitalize(
                rowData?.first_name
              )} ${capitalize(rowData?.last_name)}`;
              setCurrentPatient(formattedName);
              setmrn(rowData?.mrn);
              setLastReadMessageId(rowData?.last_read_message_id);
              toggleChat(rowData?.mrn, rowData);
              setFname(rowData?.first_name);
              setLname(rowData?.last_name)
            }}
          >
            <ChatBubble className="chat" />
            {!isRead && islastread != null ? (
              <Badge className="dots"></Badge>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const displayflag = (rowData) => {
    const isFlag = rowData?.is_flag;
    return (
      <div className="d-flex align-items-center justify-content-center">
        {isFlag && (
          <i className="pi pi-flag-fill " style={{ fontSize: '1.3rem', color: "#000000 " }}></i>
        )}
      </div>
    );
  };

  const mrnBodyTemplate = ({ mrn }) => {
    const { isUserPermission } = tableData;

    const linkContent = (
      <Link
        className="custom-link custom-target-icon d-inline-block"
        to={`${process.env.REACT_APP_MRN_LINK}/${Number(mrn).toString(16).toUpperCase()}/patient-home`}
      >
        <span>{mrn}</span>
      </Link>
    );

    const plainContent = <span>{mrn}</span>;

    return (
      <div className="d-inline-block mrn-number" style={newstart && path === "/" ? { cursor: "pointer" } : {}}>
        {newstart && path === "/" ? linkContent : plainContent}
      </div>
    );
  };

  const updateComplete = (rowData) => {
    const taskNameMappings = {
      D: "Demographics",
      BC: "Benefits Check",
      OI: "Order Information",
      CR: "Clinical Review",
      A: "Authorizations",
      NPN: "Nursing and Pharmacy Notification",
      NS: "Nursing Scheduling",
      PDS: "Pharmacy Delivery Schedule",
    };

    const completedTasks = rowData?.completed_task
      ? rowData?.completed_task?.split(",")
      : [];

    const mappedTasks = completedTasks?.map((taskKey) => taskNameMappings[taskKey.trim()]);
    const editedTaskData = mappedTasks?.join("\n");

    return (
      <>
        <Tooltip target=".custom-target-icon" className="custom-tooltip" />
        <div
          className="custom-target-icon d-inline-block"
          data-pr-tooltip={editedTaskData}
          data-pr-position="bottom"
          style={{ cursor: "pointer" }}
        >
          {rowData?.completed_task}
        </div>
        <Tooltip title={editedTaskData} className="d-inline-block">
          {rowData?.completed_task}
        </Tooltip>
      </>
    );
  };

  const getDotClassName = (status) => {
    switch (status) {
      case "Active":
        return "bg-success";
      case "Cancelled":
        return "bg-warning";
      case "Inactive":
        return "bg-danger";
      case "On Hold":
        return "bg-blue";
      case "Pending":
        return "bg-yellow";
      default:
        return "default";
    }
  };

  const firstNameBodyTemplate = (rowData) => {
    const patientstatus = rowData?.patient_status;
    const mrn = rowData?.mrn;

    const dotClassName = getDotClassName(patientstatus);
    if (newstart === true && path === "/") {
      return (
        <>
          <Tooltip target=".custom-target-icon" className="custom-tooltip" />
          <div
            data-bs-toggle="tooltip"
            data-bs-title="Default tooltip"
            id={`mrnLink-${patientstatus}`}
            data-pr-tooltip={`${patientstatus}`}
            data-pr-position="bottom"
            className={`custom-target-icon d-inline-block dot me-2 ${dotClassName}`}
          ></div>
          <div className="d-inline-block" style={{ cursor: "pointer" }}>
            <Link
              className="custom-link custom-target-icon d-inline-block"
              to={`${process.env.REACT_APP_MRN_LINK}/${Number(mrn).toString(16).toUpperCase()}/patient-home`}
            >
              <span> {rowData?.first_name}</span>
            </Link>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Tooltip target=".custom-target-icon" className="custom-tooltip" />

          <div
            data-bs-toggle="tooltip"
            data-bs-title="Default tooltip"
            id={`mrnLink-${patientstatus}`}
            data-pr-tooltip={`${patientstatus}`}
            data-pr-position="bottom"
            className={` custom-target-icon d-inline-block dot me-2 ${patientstatus === "Active"
              ? "bg-success"
              : patientstatus === "Cancelled"
                ? "bg-warning"
                : patientstatus === "Inactive"
                  ? "bg-danger"
                  : patientstatus === "On Hold"
                    ? "bg-blue"
                    : patientstatus === "Pending"
                      ? "bg-yellow"
                      : "default"
              }`}
          ></div>
          <div className="d-inline-block">
            <span>{rowData?.first_name}</span>
          </div>
        </>
      );
    }
  };

  const customHeader = () => {
    return (
      <>
        <Tooltip target=".custom-target-icon" className="custom-tooltip" />
        <button
          data-pr-tooltip="Refresh"
          data-pr-position="bottom"
          className="custom-target-icon common-btn ms-2 refresh-btn"
          onClick={() => {
            if (path === "/no-go") {
              dispatch(fetchAllNogo());
            } else {
              dispatch(fetchAll());
              dispatch(getStatusDescription());
              dispatch(getRoleNames());
            }
          }}
        >
          <i className="pi pi-replay" />
        </button>
      </>
    );
  };

  useEffect(() => {
    setLastReadMessageCount(lastReadMessageId);
  }, [lastReadMessageId]);

  const toggleDocument = (id, rowData) => {
    dispatch(documentmodal({ isOpen: true, id: id, rowData: rowData }));
  };

  const DocumentIcon = (rowData) => {
    return (
      <div className="document" onClick={() => { toggleDocument(rowData?.mrn, rowData); setmrn(rowData?.mrn); setModalfullname(rowData?.full_name) }} style={{ cursor: "pointer" }}>
        <img src={document} alt="document" width={"24px"} height={"24px"} />
      </div>
    );
  };

  const pendingStatusBody = (rowData) => {
    return (
      // <div className="" style={{ background: "#0a3e71", height: "15px", width: "15px", borderRadius: "50%" }} onClick={() => dispatch(togglePendingStatusModal({ isOpen: true, data: rowData }))}>{rowData?.pending_sub_status}</div>
      <div className="" style={{ cursor: "pointer" }} onClick={() => dispatch(togglePendingStatusModal({ isOpen: true, data: rowData }))}>{rowData?.pending_sub_status}</div>
    );
  };

  const filterNpsMcrUcString = (data, selectedValues) => {
    if (!selectedValues || selectedValues?.length === 0) return true;
    const hasBlankSelection = selectedValues?.includes('');

    // Check if the record's nps_mcr_uc_string is empty or contains any of the selected values
    return (
      (hasBlankSelection && (data?.nps_mcr_uc_string === '' || data?.nps_mcr_uc_string === undefined)) ||
      selectedValues.some(selectedValue =>
        data?.nps_mcr_uc_string && data?.nps_mcr_uc_string?.split(',')?.includes(selectedValue)
      )
    );
  };

  const pinBody = (rowData) => {

    const pinUnpinPatient = async (is_flag) => {
      dispatch(addUpdatePatientPinRequest({
        ops_order_id: rowData?.ops_order_id ? rowData?.ops_order_id : 0,
        mrn: rowData?.mrn?.toString(),
        is_flag: is_flag,
        created_by: userEmail,
      }));
    };

    return (
      <div>
        <Tooltip target=".pin-icon" className="custom-tooltip" />
        {rowData?.is_pinned ? (
          <img
            src={PinFill}
            alt="Pin"
            className="pin-icon"
            data-bs-toggle="tooltip"
            data-bs-title="Default tooltip"
            data-pr-tooltip="Unpin Patient"
            data-pr-position="bottom"
            onClick={() => pinUnpinPatient(false)}
            height={20}
            width={20}
          />
        ) : (
          <img
            src={Pin}
            alt="Pin"
            className="pin-icon"
            data-bs-toggle="tooltip"
            data-bs-title="Default tooltip"
            data-pr-tooltip="Pin Patient"
            data-pr-position="bottom"
            onClick={() => pinUnpinPatient(true)}
            height={20}
            width={20}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <ToastContainer />
      {!loading && (
        <>
          <Filterdata
            Mrnsearch={
              <MrnSearch
                data={showInput ? tableData?.items : tableData?.nongoItem}
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            }
            FirstNamesearch={
              <FirstName
                data={showInput ? tableData?.items : tableData?.nongoItem}
                filters={filters}
                handleFilterChange={handleFilterChange}
                globalFilterValue={globalFilterValue}
                onGlobalFilterChange={onGlobalFilterChange}
              />
            }
            renderAdvancedFilters={
              <AdvancedFilter
                filterOptions={showInput ? filterOptions : nogoOptions}
                data={showInput ? tableData?.items : tableData?.nongoItem}
                globalFilterOptions={globalFilterOptions}
                setGlobalFilterOptions={setGlobalFilterOptions}
                handleClear={handleClear}
                showAdvancedFilter={showAdvancedFilter}
                handleAdvancedFilter={handleAdvancedFilter}
                userEmail={userEmail}
              />

            }
          />
          {tableData && (
            <div
              className={`card ${showAdvancedFilter ? "custom-padding" : "mt-0"
                } `}
            >

              <div className="table-responsive" style={{ overflowX: "auto" }}>
                <CustomDataTable
                  data={showInput ? tableData?.items?.filter(item => filterNpsMcrUcString(item, globalFilterOptions.nps_mcr_uc_string)) : tableData?.nongoItem?.filter(item => filterNpsMcrUcString(item, globalFilterOptions?.nps_mcr_uc_string))}
                  filters={{
                    ...filters,
                    site: {
                      value: globalFilterOptions.selectedSites,
                      matchMode: FilterMatchMode.IN,
                    },
                    assigned_to: {
                      value: globalFilterOptions.selectedAssignees,
                      matchMode: FilterMatchMode.IN,
                    },
                    intake: {
                      value: globalFilterOptions.selectedIntakes,
                      matchMode: FilterMatchMode.IN,
                    },
                    case_manager: {
                      value: globalFilterOptions.selectedCaseManagers,
                      matchMode: FilterMatchMode.IN,
                    },
                    pat_category: {
                      value: globalFilterOptions.selectedCategories,
                      matchMode: FilterMatchMode.IN,
                    },
                    prescriber_name: {
                      value: globalFilterOptions.selectedPrescribers,
                      matchMode: FilterMatchMode.IN,
                    },
                    teams: {
                      value: globalFilterOptions.selectedTeams,
                      matchMode: FilterMatchMode.IN,
                    },
                    patient_status: {
                      value: globalFilterOptions.selectedstatus,
                      matchMode: FilterMatchMode.IN,
                    },
                    next_action_by: {
                      value: globalFilterOptions.seclectNextion,
                      matchMode: FilterMatchMode.IN,
                    },
                    is_flag: {
                      value: globalFilterOptions.slectFlag,
                      matchMode: FilterMatchMode.IN,
                    },
                    nogo_reason: {
                      value: globalFilterOptions.selectNoGo,
                      matchMode: FilterMatchMode.IN,
                    },
                  }}
                  loading={tableData?.loading}
                  selectedCustomers={globalFilterOptions.selectedCustomers}
                  onSelectionChange={(e) =>
                    setGlobalFilterOptions((prev) => ({
                      ...prev,
                      selectedCustomers: e.value,
                    }))
                  }
                  chatModal={chatModal}
                  idTemplate={idTemplate}
                  updateComplete={updateComplete}
                  mrnBodyTemplate={mrnBodyTemplate}
                  firstNameBodyTemplate={firstNameBodyTemplate}
                  customHeader={customHeader}
                  globalFilterValue={globalFilterValue}
                  documentModal={DocumentIcon}
                  displayflag={displayflag}
                  pendingStatusBody={pendingStatusBody}
                  pinBody={pinBody}
                />
                <ChatModal
                  currentPatient={currentPatient}
                  mrn={mrn}
                  userName={userEmail}
                  messages={tableData?.items?.messages || []}
                  lastReadMessageCount={lastReadMessageCount}
                  lastReadMessageId={lastReadMessageId}
                  userEmail={userEmail}
                  firstName={fname}
                  lastName={lname}
                />
                <UpdateTaskModal
                  userRole={userRole}
                  dob={dob}
                  name={name}
                  categories={patCategory}
                  payer={payer}
                  prescriberState={prescriberState}
                  patientmrn={patientmrn}
                  patientState={patientState}
                  userEmail={userEmail}
                  patientfullName={patientfullName}
                />
                <DocumentModal mrn={mrn} fullname={modalfullname} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PatientList;
