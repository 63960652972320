import React from 'react'

const DateFormatter = ({ date }) => {


    const formatDate = (inputDate) => {
        if (
            inputDate === "0001-01-01T00:00:00" ||
            inputDate === "0190-01-01T00:00:00" ||
            inputDate === "1900-01-01T00:00:00" ||
            inputDate === null ||
            inputDate === "01/01/1900"
        ) {
            return null;
        }

        const newDate = new Date(inputDate)?.getDate();
        const newMonths = new Date(inputDate)?.getMonth() + 1;
        const newYear = new Date(inputDate)?.getFullYear();
        let editedDate = newDate;
        let editedMonth = newMonths;
        let editedYear = newYear;

        if (newDate?.toString()?.length === 1) {
            editedDate = `0${newDate}`;
        }
        if (newMonths?.toString()?.length === 1) {
            editedMonth = `0${newMonths}`;
        }
        if (newYear?.toString()?.length === 1) {
            editedYear = `000${newYear}`;
        } else if (newYear?.toString()?.length === 2) {
            editedYear = `00${newYear}`;
        } else if (newYear?.toString()?.length === 3) {
            editedYear = `0${newYear}`;
        }

        return `${editedMonth}-${editedDate}-${editedYear}`
    };

    return (
        <>
            <span>{formatDate(date)}</span>
        </>
    )
}

export default DateFormatter