import React, { useCallback, useEffect, useState } from "react";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { getAssignRoleByUser } from "../redux/permissionSlice";




const PrivateRoute = ({ element, userEmail }) => {
    const [userPermission, setUserPermission] = useState();

    const [loading, setLoading] = useState(true);
    const [fromNavigation, setFromNavigation] = useState("");
    const dispatch = useDispatch();

    const { assignRoleByUser } = useSelector((state) => state.permissions);

    const location = useLocation();

    useEffect(() => {
        if (userEmail) {
            setLoading(true);
            // dispatch(getAssignRoleByUser(userEmail));
        }
    }, [userEmail, dispatch]);

    useEffect(() => {
        if (assignRoleByUser?.length > 0) {
            const permissions = assignRoleByUser?.reduce((acc, { page, read, deny }) => {
                acc[page] = { read, deny };
                return acc;
            }, {});

            setUserPermission(permissions);
            setLoading(false);
        }
    }, [assignRoleByUser]);



    useEffect(() => {
        const currentPage = sidebarItems?.find(
            (item) => item.to === location.pathname
        );
        if (currentPage) {
            setFromNavigation(currentPage.page);
        }
    }, [location.pathname]);

    const sidebarItems = [
        { to: "/", label: "New Starts", SchemaName: "new-starts", isExternal: false, page: "New Starts" },
        { to: "/expiring_auth", label: "Expiring Authorizations", SchemaName: "expiring-auth", isExternal: false, page: "Expiring Authorizations" },
        { to: "/no-go", label: "No-Go's", SchemaName: "no-go", isExternal: false, page: "No Go" },
        { to: "/new-referrals", label: "New Referrals", SchemaName: "new-referrals", isExternal: false, page: "New Referrals" },
    ];

    const render = useCallback(() => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (userPermission) {
            const permissions = userPermission[fromNavigation] || null;



            if (!permissions || permissions.deny === "Y") {
                return (
                    <Navigate
                        to="/unauthorized"
                        state={{ from: location.pathname, page: fromNavigation }}
                    />
                );
            }

            return permissions.read === "Y" ? (
                element
            ) : (
                <Navigate
                    to="/unauthorized"
                    state={{ from: location.pathname, page: fromNavigation }}
                />
            );
        }

        return null;
    }, [loading, userPermission, fromNavigation, location.pathname, element]);

    return <>{render()}</>;
};

export default PrivateRoute;
