import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { addAssignPtToggle, getProgressNoteSubject } from "../../redux/addNoteSlice";
import { Modal, ModalBody, ModalHeader, Spinner, Toast } from "reactstrap";
import { displayToast } from "../../components/Toast/Toast";
import { chatfileDownload, messageList2, savePatientDocument, uploadPatientFile } from "../../redux/patientSlice";
import { getCategory, getDescription } from "../../redux/addtopatientchartslice";

export const AssignPtChart = ({ mrn, userName, userEmail }) => {
    const { noteItem, isAssign, assignItem } = useSelector((state) => state.note);
    const { category, description } = useSelector((state) => state.addtopatientchart)
    const { pdfDocumentItem } = useSelector((state) => state.patient)

    const dispatch = useDispatch();

    const [selectedDescription, setSelectedDescription] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    console.log("pdfDocumentItem..............", pdfDocumentItem);


    useEffect(() => {
        if (isAssign) {
            dispatch(getProgressNoteSubject());
            dispatch(getCategory());
            dispatch(getDescription());

        }
    }, [isAssign, dispatch]);

    const toggle = (rowData) => {
        dispatch(addAssignPtToggle({ isAssign: !isAssign, assignItem: rowData }));
        resetFields();
    };

    const resetFields = () => {
        setSelectedCategory(null);
        setSelectedDescription('');
    };

    const getCurrentTime = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1)?.toString()?.padStart(2, '0'); // Add 1 to month since it's zero-indexed
        const day = date.getDate()?.toString()?.padStart(2, '0');
        const hour = date.getHours()?.toString()?.padStart(2, '0');
        const minutes = date.getMinutes()?.toString()?.padStart(2, '0');
        return `${year}${month}${day}-${hour}${minutes}`
    };

    const toBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result); // Base64 string
            reader.onerror = (error) => reject(error);
        });
    };

    const getMimeType = (filename) => {
        const ext = filename?.split('.')?.pop()?.toLowerCase();
        switch (ext) {
            case 'png':
                return 'image/png';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'pdf':
                return 'application/pdf';
            case 'txt':
                return 'text/plain';
            case 'zip':
                return 'application/zip';
            default:
                return 'application/octet-stream'; // Default binary type
        }
    };



    const handleAttach = async () => {
        setFileUploadLoading(true);

        try {
            const response = await dispatch(chatfileDownload(assignItem?.message_text));
            const mimeType = getMimeType(assignItem?.message_text);
            const blob = new Blob([response.payload.data], { type: mimeType });
            const base64Data = await toBase64(blob);

            const newFile = base64Data?.split(",");

            if (!newFile[1]) {
                setFileUploadLoading(false);
                displayToast({ msg: "Selected file is not valid", type: "error" });
                return;
            }

            const ext = assignItem?.message_text?.split(".")?.pop()?.toLowerCase();
            const createFileName = `${mrn}_${getCurrentTime()}.${ext}`;

            const fileData = { base64File: newFile[1], filename: createFileName };

            // debugger
            const fileUploadRes = await dispatch(uploadPatientFile({ data: fileData }));



            console.log("fileUploadRes........", fileUploadRes);



            const filepath = fileUploadRes?.payload

            if (fileUploadRes.meta?.requestStatus === "fulfilled") {
                if (filepath) {

                    const date = new Date();
                    const year = date.getFullYear();
                    const month = (date.getMonth() + 1).toString().padStart(2, "0");
                    const day = date.getDate().toString().padStart(2, "0");

                    const saveResponse = await dispatch(
                        savePatientDocument({
                            data: {
                                mrn: `${mrn}`,
                                status_id: 5,
                                document_type_id: 0,
                                document_description: selectedDescription,
                                document_type: "ICRxH",
                                category: selectedCategory,
                                filename: filepath,
                                orig_file: filepath,
                                email_address: userName,
                                type: 1,
                                patient_document_id: 0,
                                guid_number: null,
                                document_date: `${month}/${day}/${year}`,
                                message_id: assignItem?.message_id,
                            },
                        })
                    );

                    if (saveResponse.meta?.requestStatus === "fulfilled") {
                        toggle();
                        displayToast({ msg: "Document added to Patient Chart", type: "success" });
                    }

                }
                else {
                    displayToast({ msg: "File path is missing ", type: "error" });
                }
            } else {
                displayToast({ msg: "File upload failed", type: "error" });

            }

            setFileUploadLoading(false);
            dispatch(messageList2({ mrn: `${mrn}`, usermail: userEmail }));
        } catch (error) {
            setFileUploadLoading(false);
            displayToast({ msg: error?.message || "Something went wrong", type: "error" });
            console.error(error);
        }
    };


    return (
        <>
            <Toast />
            <div className="card flex justify-content-center border-0">
                <Modal className="referral-modal1" isOpen={isAssign} toggle={() => toggle(noteItem)} centered backdrop={"static"}>
                    <ModalHeader className="w-100 align-items-start pb-2" toggle={() => toggle(noteItem)}>
                        Assign to Patient
                    </ModalHeader>
                    <ModalBody>
                        <form className="custom-form email-form physician-form">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label htmlFor="category">Category</label>
                                </div>
                                <div className="col-md-9">
                                    <Dropdown
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        id="category"
                                        value={selectedCategory}
                                        options={category.map((item) => item.document_category)}
                                        onChange={(e) => setSelectedCategory(e.value)}
                                        placeholder="Select a Category"
                                        className="w-full"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <label htmlFor="description">Description</label>
                                </div>
                                <div className="col-md-9">
                                    <Dropdown
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        id="description"
                                        value={selectedDescription}
                                        options={description.map((item) => item.document_description)}
                                        onChange={(e) => setSelectedDescription(e.value)}
                                        placeholder="Select a Description"
                                        className="w-full"
                                    />
                                </div>
                            </div>
                            <div className="d-flex mt-2 border-top pt-3 mt-3 justify-content-end">
                                <button type="button" className="custom-button me-2" onClick={() => toggle(noteItem)} >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className={`common-btn ${!selectedCategory || !selectedDescription || fileUploadLoading ? "disabled" : ""}`}
                                    onClick={handleAttach}
                                    disabled={!selectedCategory || !selectedDescription || fileUploadLoading}
                                >
                                    {fileUploadLoading && <Spinner className="me-2" size="sm" color="light" />}
                                    Attach
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};