import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode, FilterService } from "primereact/api";
import Filterdata from "../../components/Filter/Filterdata";
import { useDispatch, useSelector } from "react-redux";
import {
  authfetchAll,
  fileDownload,
  getIntakeReportList,
  toggleEmailModal,
  updateCheckbox,
  uploadFileForExpiringAuth,
} from "../../redux/expiringauthSlice";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import ExpiringAuthsDataTable from "../../components/Filter/ExpiringAuthsDataTable";
import { Checkbox } from "primereact/checkbox";
import { Download, Email, UploadFile } from "@mui/icons-material";
import MrnSearch from "../../components/Filter/MrnSearch";
import FirstName from "../../components/Filter/FirstName";
import DateRangeFilter from "../../components/Filter/DateRangeFilter";
import { expiringFilterOptions } from "../../components/Filter/NewstartFilterField";
import AdvancedFilter from "../../components/Filter/AdvanceFilter";
import { Dropdown } from "primereact/dropdown";
import EmailSendModal from "./EmailSendModal";
import { Toast } from 'primereact/toast';
import { toast, ToastContainer } from 'react-toastify';
import { patientExpiringAuthPermission } from "../../redux/patientSlice";


export default function ExpiringAuths({ name, userEmail }) {



  const { items, isUserExpAuthPermission, userRoles, intakeList, isEmailOpen, loading } = useSelector((state) => state.expiringAuth);
  const permission = useSelector((state) => state.patient);
  const dispatch = useDispatch();

  const { assignRoleByUser } = useSelector((state) => state.permissions)

  const newstart = assignRoleByUser?.some(role =>
    role.page === "Expiring Authorizations" &&
    role.read === "Y" &&
    (role.role === "Super User" || role.role === "Care Transition Coordinator")
  );


  useEffect(() => {
    dispatch(patientExpiringAuthPermission(userEmail))
  }, [permission])

  const [mrncheck, setMrncheck] = useState([]);
  const [newdata, setNewdata] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [filemrn, setFilemrn] = useState(null);
  const [namePopup, setNamePopup] = useState('');
  const [lastName, setLastname] = useState('');
  const [prescriber, setPrescriber] = useState('');
  const [expDate, setExpDate] = useState('');
  const [order, setOrder] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [dob, setDOB] = useState('');
  const [selectedCities, setSelectedCities] = useState([]);

  const [prescriberemail, setPrescriberemail] = useState('');
  const [prescriberName, setPrescriberName] = useState(null);
  const [mrn, setMrn] = useState('');
  const [emailId, setEmailId] = useState();

  const [medicineauthorization, setMedicineauthorization] = useState('');
  const [patientid, sePatientid] = useState('');
  const [isAssign, setIsAssign] = useState(false);
  const [medicationid, setMedicationId] = useState([]);
  const options = Array.isArray(intakeList?.data?.data) ? intakeList?.data?.data : [];

  const [globalFilterOptions, setGlobalFilterOptions] = useState({
    selectedCustomers: [],
    selectedSites: [],
    selectedAssignees: [],
    selectedIntakes: [],
    selectedCaseManagers: [],
    selectedCategories: [],
    selectedPrescribers: [],
    selectedTeams: [],
    selectedStatus: [],
    sitees: [],
    intake: [],
    caseManager: [],
    assignedTo: [],
    categories: [],
    prescriberes: [],
    team: [],
    status: [],
    selectedstatus: [],
    payors: [],
    assign: [],
    selectedAssign: [],
    seltectIntake: [],
  });







  useEffect(() => {
    const startDate = new Date(selectedDate).getTime();
    const endDate = new Date(selectedEndDate).getTime();
    if (selectedDate && selectedEndDate) {
      const filteredData = items?.filter((item) => {
        const itemDate = new Date(item?.authorization_stop_date).getTime();
        return itemDate >= startDate && itemDate <= endDate;
      });
      setNewdata(filteredData);
      return;
    }
    setNewdata(items);
  }, [selectedDate, selectedEndDate, items]);


  useEffect(() => {
    if (selectedDate) {
      const startDate = new Date(selectedDate).getTime();
      const filteredData = items?.filter((item) => {
        const itemDate = new Date(item?.authorization_stop_date).getTime();
        return itemDate >= startDate;
      });
    }
  }, [selectedDate, items]);

  useEffect(() => {
    if (selectedEndDate) {
      const endDate = new Date(selectedEndDate).getTime();
      const filteredData = items?.filter((item) => {
        const itemDate = new Date(item?.authorization_stop_date).getTime();
        return itemDate <= endDate;
      });
    }
  }, [selectedEndDate, items]);

  useEffect(() => {
    FilterService.register("custom_authorization_stop_date", (value) => {
      if (!selectedEndDate) return true;
      const endDate = new Date(selectedEndDate).getTime();
      const itemDate = new Date(value).getTime();
      return itemDate <= endDate;
    });
  }, [selectedEndDate]);

  useEffect(() => {
    FilterService.register("custom_authorization_stop_date", (value) => {
      if (!selectedDate) return true;
      const startDate = new Date(selectedDate).getTime();
      const itemDate = new Date(value).getTime();
      return itemDate >= startDate;
    });
  }, [selectedDate]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    site_name: { value: null, matchMode: FilterMatchMode.EQUALS },
    case_manager: { value: null, matchMode: FilterMatchMode.EQUALS },
    sales_rep: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    prescriber_name: { value: null, matchMode: FilterMatchMode.EQUALS },
    teams: { value: null, matchMode: FilterMatchMode.EQUALS },
    assign_emp: { value: null, matchMode: FilterMatchMode.EQUALS },
    patient_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    insuranse_comp: { value: null, matchMode: FilterMatchMode.EQUALS },
    intake_report: { value: null, matchMode: FilterMatchMode.EQUALS },
    payor: { value: null, matchMode: FilterMatchMode.EQUALS },
    authorization_stop_date: {
      value: null,
      matchMode: FilterMatchMode.CUSTOM,
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");


  const [showAdvancedFilter, setshowAdvancedFilter] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (userEmail !== null && userEmail !== " " && userEmail !== "") {
      dispatch(authfetchAll(userEmail));
    }
    const intervalId = setInterval(() => {
      dispatch(authfetchAll(userEmail));
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [userEmail]);

  const fetchData = () => {
    dispatch(authfetchAll(userEmail));
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleRefreshClick = () => {
    fetchData();
  };

  const handleAdvancedFilter = () => {
    setshowAdvancedFilter(!showAdvancedFilter);
  };

  const handleClear = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      site_name: { value: null, matchMode: FilterMatchMode.EQUALS },
      case_manager: { value: null, matchMode: FilterMatchMode.EQUALS },
      sales_rep: { value: null, matchMode: FilterMatchMode.EQUALS },
      category: { value: null, matchMode: FilterMatchMode.EQUALS },
      prescriber_name: { value: null, matchMode: FilterMatchMode.EQUALS },
      teams: { value: null, matchMode: FilterMatchMode.EQUALS },
      patient_status: { value: null, matchMode: FilterMatchMode.EQUALS },
      insuranse_comp: { value: null, matchMode: FilterMatchMode.EQUALS },
      assign_emp: { value: null, matchMode: FilterMatchMode.EQUALS },
      payor: { value: null, matchMode: FilterMatchMode.EQUALS },
      intake_report: { value: null, matchMode: FilterMatchMode.EQUALS },
      authorization_stop_date: {
        value: null,
        matchMode: FilterMatchMode.CUSTOM,
      },
    });

    setGlobalFilterValue("");
    setGlobalFilterOptions({
      selectedCustomers: [],
      selectedSites: [],
      selectedAssignees: [],
      selectedIntakes: [],
      selectedCaseManagers: [],
      selectedCategories: [],
      selectedPrescribers: [],
      selectedTeams: [],
      selectedStatus: [],
      seltectIntake: [],
      sitees: [],
      intake: [],
      caseManager: [],
      assignedTo: [],
      categories: [],
      prescriberes: [],
      team: [],
      status: [],
      selectedstatus: [],
      payors: [],
      selectedAssign: [],
      assign: [],

    });
    setSelectedDate("");
    setSelectedEndDate("");
  };

  const handleFilterChange = (e, field) => {
    if (!e || (typeof e !== "object" && !Array.isArray(e))) {
      return;
    }

    let updatedFilters = { ...filters };


    if (field === "full_name") {
      const value = e.target.value || "";

      updatedFilters[field] = {
        value: value,
        matchMode: 'contains',
      };
    } else if (field === "mrn") {
      const value = e.target ? e.target.value : "";

      updatedFilters[field] = {
        value: value,
        matchMode: 'startsWith',
      };
    }

    else if (field === "authorization_stop_date") {
      const { from, to } = e;

      updatedFilters[field] = {
        ...updatedFilters[field],
        from: from,
        to: to,
        matchMode: 'custom',
      };
    } else {
      const value = e.target ? e.target.value : "";

      updatedFilters[field] = {
        value: value,
        matchMode: 'contains',
      };
    }
    setFilters(updatedFilters);
  };


  const formatDate = (date) => {
    if (!date) return "";

    const newDate = new Date(date);
    const editedDate = newDate?.getDate()?.toString()?.padStart(2, "0");
    const editedMonth = (newDate?.getMonth() + 1)?.toString()?.padStart(2, "0");
    const editedYear = newDate?.getFullYear()?.toString()?.padStart(4, "0");

    return `${editedMonth}-${editedDate}-${editedYear}`;
  };

  const dateBody = (rowData) => formatDate(rowData?.authorization_stop_date);
  const nextfill = (rowData) => formatDate(rowData?.nextfill);
  const workDate = (rowData) => formatDate(rowData?.assign_date);

  const displayToast = ({ msg, type }) => toast[type](msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleCheckboxChange = (
    e,
    authorization,
    employeeid,
    mrn,
    workuser,
    status,
    medicine_authorization_id
  ) => {
    const isChecked = e.target.checked;
    const isSuperUser =
      permission?.role === "Super User" || permission?.role === "Operations" || permission?.role === "Intake Manager";
    const workusername = workuser === name;

    if (!isSuperUser && !status && !isChecked && workusername) return;

    setMrncheck((prev) => {
      return prev.map((item) => {


        if (item?.medicine_authorization_id === medicine_authorization_id) {
          return {
            ...item,
            work_status: isChecked,
            work_user: isChecked ? name : null,
          };
        }
        return item;
      });
    });

    const inputJsonString = `{"medicine_authorization_id": [${authorization}]}`;

    const dataPayload = {
      medicine_authorization_id: [authorization],
      inputjson: inputJsonString,
      employee_id: null,
      flag_status: isChecked,
      created_by: userEmail,
      work_status: isChecked,
      work_user: isChecked ? name : null,
    }
    dispatch(updateCheckbox({ dataPayload }));
    dispatch(authfetchAll(userEmail));
  };

  const mrnBodyTemplate = (rowData) => {

    const isCheckBoxChecked = rowData?.work_status;
    const formattedDate = dateBody(rowData);
    const authorization = rowData?.medicine_authorization_id;
    const employeeid = rowData?.employee_id;
    const mrn = rowData?.mrn;
    const allowedRoles = ["Super User", "Operations", "Intake Manager"];
    if (newstart === true) {
      return (
        <div className="d-flex justify-content-inline-flex ">
          {isCheckBoxChecked ? (
            <>
              <Tooltip
                target=".custom-target-icon"
                className="custom-tooltip"
              />
              <Checkbox
                data-pr-tooltip={`${rowData?.assign_emp} is Working on the Patient ${workDate(rowData)}`}
                data-pr-position="bottom"
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    authorization,
                    employeeid,
                    rowData?.mrn,
                    rowData?.work_user,
                    isCheckBoxChecked,
                    rowData?.medicine_authorization_id
                  )
                }
                checked={isCheckBoxChecked !== false}
                disabled={
                  !(
                    allowedRoles?.includes(permission?.role) ||
                    (rowData?.assign_emp === name && isCheckBoxChecked)
                  )
                }
                className="me-2 check-list p-highlight custom-target-icon"
              />
            </>
          ) : (
            <>
              <Checkbox
                onChange={(e) => {
                  handleCheckboxChange(
                    e,
                    authorization,
                    employeeid,
                    rowData?.mrn,
                    rowData?.assign_emp,
                    isCheckBoxChecked
                  );
                }}
                checked={isCheckBoxChecked !== false}
                className="me-2 check-list p-highlight"
              />
            </>
          )}
          <div className="d-inline-block" style={{ cursor: "pointer" }}>
            <Link
              className="custom-link  d-inline-block"
              to={`${process.env.REACT_APP_MRN_LINK}/${Number(mrn).toString(16).toUpperCase()}/patient-home`}
            >
              <span>{rowData?.mrn}</span>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-inline-flex ">
          {isCheckBoxChecked ? (
            <>
              <Tooltip
                target=".custom-target-icon"
                className="custom-tooltip"
              />
              <Checkbox
                data-pr-tooltip={`${rowData?.work_user} is Working on the Patient ${formattedDate}`}
                data-pr-position="bottom"
                onChange={(e) =>
                  handleCheckboxChange(
                    e,
                    authorization,
                    employeeid,
                    rowData?.mrn,
                    rowData?.assign_emp,
                    isCheckBoxChecked
                  )
                }
                checked={isCheckBoxChecked !== false}
                disabled={
                  !(
                    allowedRoles?.includes(permission?.role) ||
                    (rowData?.assign_emp === name && isCheckBoxChecked)
                  )
                }
                className="me-2 check-list p-highlight custom-target-icon"
              />
            </>
          ) : (
            <>
              <Checkbox
                onChange={(e) => {
                  handleCheckboxChange(
                    e,
                    authorization,
                    employeeid,
                    rowData?.mrn,
                    rowData?.assign_emp,
                    isCheckBoxChecked
                  );
                }}
                checked={isCheckBoxChecked !== false}
                className="me-2 check-list p-highlight"
              />
            </>
          )}
          <div className="d-inline-block" style={{ cursor: "pointer" }}>
            <span>{rowData?.mrn}</span>
          </div>
        </div>
      );
    }
  };
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const days = (rowData) => {
    const days = rowData?.remain_days;

    return (
      <div
        className={`text-center ${days <= 0
          ? `text-danger`
          : days >= 0 && days <= 30
            ? `yellow`
            : days >= 30 && days <= 90
              ? `text-warning`
              : `text-dark`
          }`}
      >
        {days + " days"}
      </div>
    );
  };

  const expiryDateBodyTemplate = (rowData) => {
    const days = rowData?.remain_days;

    return (
      <>
        <div
          className={`d-inline-block dot me-2 ${days <= 0
            ? `bg-danger`
            : days >= 0 && days <= 30
              ? `yellowbg`
              : days >= 30 && days <= 90
                ? `bg-warning`
                : `bg-dark`
            }`}
        ></div>
        <span>{dateBody(rowData)}</span>
      </>
    );
  };

  const handleImageClick = (mrn) => {
    setFilemrn(mrn);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const FileDownload = async (filename) => {
    try {
      const response = await dispatch(fileDownload(filename));
      const blob = new Blob([response.payload.data], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file === null) return;
    const formData = new FormData();

    formData.append("file", file);
    formData.append("mrn", filemrn);
    formData.append("emailID", userEmail);




    try {
      const response = await dispatch(uploadFileForExpiringAuth(formData));
      dispatch(authfetchAll(userEmail));
    } catch (error) {
      console.error("Error uploading:", error);
    }
  };

  const uploadBodyTemplate = (rowdata) => {




    const showDownload = rowdata?.is_file_uploaded;
    const mrn = rowdata?.mrn;


    const filename = rowdata?.file_name;
    const trimmedFilename = filename
      ?.replace("/happ-expauth-dev/", "")
      ?.slice(36);

    return (
      <>
        {showDownload ? (
          <>
            <Tooltip target=".custom-target-icon" className="custom-tooltip" />
            <Download
              data-pr-tooltip={`${trimmedFilename}`}
              data-pr-position="bottom"
              className="custom-target-icon custom-icon-width"
              onClick={() => {
                FileDownload(rowdata?.file_name);
              }}
            />
          </>
        ) : (
          <>
            <UploadFile
              className="custom-icon-width upload"
              onClick={() => {
                handleImageClick(mrn);
              }}
            />
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e, mrn);
              }}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </>
        )}
      </>
    );
  };

  const customHeader = () => {
    return (
      <>
        <Tooltip target=".custom-target-icon" className="custom-tooltip" />
        <button
          data-pr-tooltip="Refresh"
          data-pr-position="bottom"
          className="custom-target-icon common-btn ms-2 refresh-btn"
          onClick={handleRefreshClick}
        >
          <i className="pi pi-replay" />
        </button>
      </>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Active":
        return "bg-success";
      case "Cancelled":
        return "bg-warning";
      case "Inactive":
        return "bg-danger";
      case "On Hold":
        return "bg-blue";
      case "Pending":
        return "bg-yellow";
      default:
        return "default";
    }
  };

  const firstNameBodyTemplate = (rowData) => {
    const status = rowData?.patient_status;
    const statusClass = getStatusClass(status);
    const mrn = rowData?.mrn;
    return (
      <>
        <Tooltip target=".custom-target-icon" className="custom-tooltip" />

        <div
          data-bs-toggle="tooltip"
          data-bs-title="Default tooltip"
          id={`mrnLink-${status}`}
          data-pr-tooltip={`${status}`}
          data-pr-position="bottom"
          className={`custom-target-icon d-inline-block dot me-2 ${statusClass}`}
        ></div>

        {newstart ? (
          <Link
            className="custom-link  d-inline-block"
            to={`${process.env.REACT_APP_MRN_LINK}/${Number(mrn)?.toString(16)?.toUpperCase()}/patient-home`}
          >
            <span>{rowData?.first_name}</span>
          </Link>
        ) : (
          <div className="d-inline-block">
            <span>{rowData?.first_name}</span>
          </div>
        )}
      </>
    );
  };



  const toggleChat = (rowData) => {
    dispatch(toggleEmailModal({ isEmailOpen: !isEmailOpen, rowData: rowData }));
  };
  const EmailModal = (rowData) => {

    const data = rowData?.email_sent_to;
    if (data === null) {
      return (
        <>
          <Tooltip target=".custom-target-icon" className="custom-tooltip" />
          <div onClick={() => { toggleChat(rowData); setNamePopup(rowData?.first_name); setDiagnosis(rowData?.primary_diagnosis); setOrder(rowData?.order); setPrescriber(rowData?.prescriber_name); setDOB(formatDate(rowData?.date_of_birth)); setExpDate(formatDate(rowData?.authorization_stop_date)); setPrescriberemail(rowData?.prescriber_email); setMrn(rowData?.mrn); setEmailId(rowData?.employee_id); setMedicineauthorization(rowData?.medicine_authorization_id); sePatientid(rowData?.patient_id); setPrescriberName(rowData?.prescriber_name); setLastname(rowData?.last_name) }}>
            <Email data-pr-tooltip={`Send as email to physician`}
              data-pr-position="bottom"
              className="chat custom-target-icon custom-icon-width"
            />
          </div>
        </>
      )
    }
    else {
      var tooltip = `Email has been sent to ${rowData?.prescriber_name} on ${formatDate(rowData?.email_sent_datetime)}`;
      return (
        <>
          <Tooltip target=".custom-target-icon" className="custom-tooltip" />
          <div>
            <Email data-pr-tooltip={tooltip}
              data-pr-position="bottom"
              className=" custom-target-icon grey-icon"
            />
          </div>
        </>
      )
    }

  }


  useEffect(() => {
    dispatch(getIntakeReportList())
  }, [])




  const handleAssign = () => {
    try {
      const inputJsonString = `{"medicine_authorization_id": [${medicationid}]}`;

      const dataPayload = {
        inputjson: inputJsonString,
        medicine_authorization_id: medicationid,
        employee_id: selectedCities?.employee_id,
        flag_status: true,
        created_by: userEmail,
        newdata: newdata,
      }

      dispatch(updateCheckbox({
        dataPayload, callback: (res) => {
          if (res?.data?.success) {
            displayToast({ msg: res?.data?.message || "Intake coordinator is assigned successfully", type: "success" })
          }
          else {
            displayToast({ msg: res?.data?.message || "Something went wrong", type: "error" })
          }
        }
      }))
      dispatch(authfetchAll(userEmail));
      setIsAssign(false);
      setSelectedCities({});
    } catch (error) {
      console.error(error);
    }
  };

  const AssignCheckbox = (rowData, ind) => {

    const isSuperUser = userRoles?.some(role =>
      ["Super User", "Operations", "Intake Manager"]?.includes(role.role)
    );

    const workStatus = rowData?.work_status === true;

    const handleAssignCheck = (e, medicationid) => {

      const isChecked = e.target.checked;


      if (!isSuperUser) return;
      const oldArray = [...newdata];

      const index = oldArray.findIndex((item) => item.medicine_authorization_id === medicationid);

      oldArray[index] = {
        ...oldArray[index],
        is_assign: isChecked,
      }
      const filtered = oldArray.filter(item => item.is_assign && isSuperUser && !item.work_status);
      const mrns = filtered?.map(item => item.medicine_authorization_id);
      setMedicationId(mrns);
      setNewdata(oldArray);

    }
    if (!workStatus) {
      return (
        <div className="d-flex justify-content-center">
          <Checkbox
            onChange={(e) => { handleAssignCheck(e, rowData?.medicine_authorization_id) }}
            checked={rowData?.is_assign}
            className="me-2 check-list p-highlight"
            disabled={!isSuperUser && !workStatus}
          />
        </div>
      );
    }
    return null;
  }

  useEffect(() => {
    setSelectedCities([]);
  }, []);



  const orderBody = (rowData) => {
    return (
      <>
        <Tooltip
          target=".custom-target-icon"
          className="custom-tooltip"
          title={rowData?.order}
        >
          {rowData?.order}
        </Tooltip>
        <div className="text-truncate custom-target-icon" style={{ width: "50px" }} data-pr-tooltip={`${rowData?.order}`}
          data-pr-position="bottom">
          {rowData?.order}
        </div>
      </>
    )
  }



  return (
    <div>
      <>
        <Toast ref={toast} className="border-0" />
        <ToastContainer />
        <div className="d-flex expiring-filter">
          <Filterdata
            Mrnsearch={
              <MrnSearch
                data={items}
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            }
            FirstNamesearch={
              <FirstName
                data={items}
                filters={filters}
                handleFilterChange={handleFilterChange}
                globalFilterValue={globalFilterValue}
                onGlobalFilterChange={onGlobalFilterChange}
              />
            }
            dateRangeFilter={
              <DateRangeFilter
                startDate={selectedDate}
                endDate={selectedEndDate}
                setStartDate={setSelectedDate}
                setEndDate={setSelectedEndDate}
              />
            }
            renderAdvancedFilters={
              <AdvancedFilter
                filterOptions={expiringFilterOptions}
                data={items}
                globalFilterOptions={globalFilterOptions}
                setGlobalFilterOptions={setGlobalFilterOptions}
                handleClear={handleClear}
                showAdvancedFilter={showAdvancedFilter}
                handleAdvancedFilter={handleAdvancedFilter}
                userRoles={userRoles}
                isAssign={isAssign}
                setIsAssign={setIsAssign}
                userEmail={userEmail}
              />

            }
          />

          {isAssign && (

            <div className="custom-form d-flex">
              <Dropdown filter value={selectedCities} onChange={(e) => setSelectedCities(e.value)} options={options} optionLabel="full_name"
                placeholder="Intake Coordinator" className="form-control w-auto" />
              <button className="custom-button m-0 mb-2  advanced-filter-button justify-content-end" onClick={handleAssign}>Assign</button>
            </div>
          )}

        </div>

        {items && (
          <div
            className={`card expiring-auth ${showAdvancedFilter ? "custom-padding" : "mt-0"
              } `}
          >
            <div className="table-responsive" style={{ overflowX: "auto" }}>
              <ExpiringAuthsDataTable
                data={newdata}
                filters={{
                  ...filters,
                  site_name: {
                    value: globalFilterOptions.selectedSites,
                    matchMode: FilterMatchMode.IN,
                  },
                  sales_rep: {
                    value: globalFilterOptions.selectedAssignees,
                    matchMode: FilterMatchMode.IN,
                  },

                  case_manager: {
                    value: globalFilterOptions.selectedCaseManagers,
                    matchMode: FilterMatchMode.IN,
                  },
                  category: {
                    value: globalFilterOptions.selectedCategories,
                    matchMode: FilterMatchMode.IN,
                  },
                  prescriber_name: {
                    value: globalFilterOptions.selectedPrescribers,
                    matchMode: FilterMatchMode.IN,
                  },
                  teams: {
                    value: globalFilterOptions.selectedTeams,
                    matchMode: FilterMatchMode.IN,
                  },
                  insuranse_comp: {
                    value: globalFilterOptions.selectedPayor,
                    matchMode: FilterMatchMode.IN,
                  },
                  patient_status: {
                    value: globalFilterOptions.selectedstatus,
                    matchMode: FilterMatchMode.IN,
                  },

                  assign_emp: {
                    value: globalFilterOptions.selectedAssign,
                    matchMode: FilterMatchMode.IN,
                  },
                  intake_report: {
                    value: globalFilterOptions.seltectIntake,
                    matchMode: FilterMatchMode.IN,
                  },
                }}
                loading={loading}
                selectedCustomers={selectedCustomers}
                onSelectionChange={(e) => setSelectedCustomers(e.value)}
                dateFilterTemplate={dateFilterTemplate}
                mrnBodyTemplate={mrnBodyTemplate}
                firstNameBodyTemplate={firstNameBodyTemplate}
                uploadBodyTemplate={uploadBodyTemplate}
                expiryDateBodyTemplate={expiryDateBodyTemplate}
                nextfill={nextfill}
                customHeader={customHeader}
                dateBody={dateBody}
                days={days}
                EmailModal={EmailModal}
                assignCheckbox={AssignCheckbox}
                isAssign={isAssign}
                orderBody={orderBody}

              />
            </div>
          </div>

        )}
        <EmailSendModal prescriberName={prescriberName} mrn={mrn} patientid={patientid} medicineauthorization={medicineauthorization} prescriberemail={prescriberemail} namePopup={namePopup} lastName={lastName} prescriber={prescriber} dob={dob} diagnosis={diagnosis} order={order} expDate={expDate} />
      </>
    </div >
  );
}
