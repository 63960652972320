import { AutoComplete } from 'primereact/autocomplete'
import React, { useState } from 'react'

const FirstName = ({ data, globalFilterValue, onGlobalFilterChange }) => {

    const [filteredNames, setFilteredNames] = useState([]);

    const searchFirstname = (event) => {
        const query = event.query?.toLowerCase();
        if (data) {
            const filteredNames = data
                ?.map((rowData) => String(rowData?.full_name))?.filter((name) => name?.toLowerCase()?.includes(query));

            setFilteredNames(filteredNames);
        }
    };

    return (
        <>
            <div className="flex justify-content-end position-relative">
                <i className="pi pi-search search-icon position-absolute top-50 translate-middle ps-5 start-0" />
                <AutoComplete
                    value={globalFilterValue}
                    suggestions={filteredNames}
                    completeMethod={searchFirstname}
                    onChange={onGlobalFilterChange}
                    className="form-control suggestion-filter w-100 firstname-input mrn-input"
                    placeholder="Search First Name, Last Name"
                />
            </div>
        </>
    )
}

export default FirstName