import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { Tooltip } from "primereact/tooltip";
import { mrnModal, patientfetchdata, referralfetchAll, toggleViewModal } from "../../redux/referralsSlice";
import ReferralDataTable from "../../components/Filter/ReferralDataTable";
import CreatePatient from "./CreateMrnModal";
import MrnPatient from "./MrnModal";
import { TabPanel, TabView } from "primereact/tabview";
import FilterSection from "../../components/Filter/FilterSection";
import document from "../../assets/images/document.svg";
import ViewDocumentModal from "./ViewDocumentModal";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";


export default function Referral({ userEmail }) {

    const dispatch = useDispatch();

    const { item, loading } = useSelector((state) => state.referral);

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (userEmail !== null && userEmail.trim() !== "") {
            dispatch(referralfetchAll(userEmail))
        }
    }, [userEmail]);

    const [globalFilterOptions, setGlobalFilterOptions] = useState({
        status: [],
        selectedstatus: [],
        selectedSites: [],
        selectedCategories: [],
        sitees: [],
        categories: [],
        prescriber: [],
    });

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        site: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        prescriber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [showAdvancedFilter, setshowAdvancedFilter] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch(referralfetchAll(userEmail));
        }, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, [userEmail]);

    const fetchData = () => {
        dispatch(referralfetchAll(userEmail))
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleRefreshClick = () => {
        fetchData();
    };

    const handleAdvancedFilter = () => {
        setshowAdvancedFilter(!showAdvancedFilter);
    };

    const handleClear = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            mrn: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            last_Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            site: { value: null, matchMode: FilterMatchMode.EQUALS },
            category: { value: null, matchMode: FilterMatchMode.EQUALS },
        });
        setGlobalFilterValue("");
        setGlobalFilterOptions({
            status: [],
            selectedstatus: [],
            selectedSites: [],
            selectedCategories: [],
            sitees: [],
            categories: [],
        });
    };

    const handleFilterChange = (e, field) => {
        if (!e || (typeof e !== "object" && !Array.isArray(e))) {
            return;
        }
        let updatedFilters = { ...filters, [field]: e };

        if (field === "mrn") {
            const value = e.target ? e.target.value : "";
            updatedFilters[field] = {
                value: value,
                matchMode: FilterMatchMode.STARTS_WITH,
            };
        } else if (field === "authorization_stop_date") {
            const { from, to } = e;
            updatedFilters[field] = {
                ...updatedFilters[field],
                from: from,
                to: to,
                matchMode: FilterMatchMode.CUSTOM,
            };
        }
        setFilters(updatedFilters);
    };

    const customHeader = () => {
        return (
            <>
                <Tooltip target=".custom-target-icon" className="custom-tooltip" />
                <button
                    data-pr-tooltip="Refresh"
                    data-pr-position="bottom"
                    className="custom-target-icon common-btn ms-2 refresh-btn"
                    onClick={handleRefreshClick}
                >
                    <i className="pi pi-replay" />
                </button>
            </>
        );
    };

    const mrnModaltoggle = (rowData) => {
        dispatch(mrnModal({ isOpen: true, rowData: rowData }));
    };

    const CreateMrn = (rowData) => {
        if (rowData?.mrn === "Pending") {
            return (
                <div className="action-button d-flex">
                    {rowData?.mrn === "Pending" ? (<div className="create-mrn me-2" onClick={() => { mrnModaltoggle(rowData); dispatch(patientfetchdata(userEmail)) }}>
                        Create New Patient
                    </div>) : null}
                </div>
            );
        }
    };

    const filterDataByStatus = (mrn) => {
        return item?.filter(data => data?.mrn === mrn);
    };

    const getCountByStatus = (mrn) => item?.filter(data => data?.mrn === mrn)?.length;

    const getCountForMRN = () => {
        const excludedStatuses = ["Pending", "Review"];
        const filteredItems = item?.filter(data => !excludedStatuses?.includes(data?.mrn));
        return filteredItems?.length;
    };

    const getCountForMRNData = () => {
        const excludedStatuses = ["Pending", "Review"];
        const filteredItems = item?.filter(data => !excludedStatuses?.includes(data?.mrn));
        return filteredItems;
    };

    const firstNameBodyTemplate = (rowData) => {
        const patientstatus = rowData?.mrn;


        return (
            <>

                {patientstatus && (
                    <Tooltip target=".custom-target-icon" className="custom-tooltip" />
                )}

                <div
                    data-bs-toggle="tooltip"
                    data-bs-title="Default tooltip"
                    id={`mrnLink-${patientstatus}`}
                    data-pr-tooltip={`${patientstatus}`}
                    data-pr-position="bottom"
                    className={` custom-target-icon d-inline-block dot me-2 ${patientstatus === "Active"
                        ? "bg-success"
                        : patientstatus === "Cancelled"
                            ? "bg-warning"
                            : patientstatus === "Inactive"
                                ? "bg-danger"
                                : patientstatus === "On Hold"
                                    ? "bg-blue"
                                    : patientstatus === "Pending"
                                        ? "bg-yellow"
                                        : "default"
                        }`}
                ></div>
                <span>{rowData?.first_name}</span>
            </>
        );
    }

    const renderDataTable = () => {
        let data = [];
        let tabFilters = { ...filters };
        switch (activeTab) {
            case 0:
                data = filterDataByStatus("Pending");
                break;
            // case 1:
            //     data = filterDataByStatus("Review");
            //     break;
            case 1:
                data = getCountForMRNData();
                break;
            default:
                data = item;
        }

        const DocumentToggle = (rowData) => {
            dispatch(toggleViewModal({ isOpen: true, rowData: rowData }))
        };

        const documentModal = (rowData) => {
            return (
                <div className="document" onClick={() => DocumentToggle(rowData)} style={{ cursor: "pointer" }}>
                    <img src={document} alt="document" width={"24px"} height={"24px"} />
                </div>
            );
        };

        return (
            <ReferralDataTable
                data={data}
                filters={{
                    ...tabFilters,
                    site: {
                        value: globalFilterOptions.selectedSites,
                        matchMode: FilterMatchMode.IN,
                    },
                    category: {
                        value: globalFilterOptions.selectedCategories,
                        matchMode: FilterMatchMode.IN,
                    },
                    category: {
                        value: globalFilterOptions.selectedCategories,
                        matchMode: FilterMatchMode.IN,
                    },
                    prescriber: {
                        value: globalFilterOptions.prescriber,
                        matchMode: FilterMatchMode.IN,
                    },
                }}
                loading={loading}
                CreateMrn={CreateMrn}
                customHeader={customHeader}
                documentModal={documentModal}
                firstNameBodyTemplate={firstNameBodyTemplate}
            />
        );
    };


    return (
        <div>
            <ToastContainer />
            <TabView className="custom-tab" activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header={<div className="custom-tab">{getCountByStatus("Pending")} <span>Pending</span></div>} >
                    <FilterSection
                        item={item}
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        globalFilterValue={globalFilterValue}
                        onGlobalFilterChange={onGlobalFilterChange}
                        globalFilterOptions={globalFilterOptions}
                        setGlobalFilterOptions={setGlobalFilterOptions}
                        handleClear={handleClear}
                        showAdvancedFilter={showAdvancedFilter}
                        handleAdvancedFilter={handleAdvancedFilter}
                    />
                    <CreatePatient userEmail={userEmail} />
                    <MrnPatient userEmail={userEmail} />
                </TabPanel>
                {/* <TabPanel header={<div className="custom-tab">{getCountByStatus("Review")} <span>Review</span></div>} >
                    <FilterSection
                        item={item}
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        globalFilterValue={globalFilterValue}
                        onGlobalFilterChange={onGlobalFilterChange}
                        globalFilterOptions={globalFilterOptions}
                        setGlobalFilterOptions={setGlobalFilterOptions}
                        handleClear={handleClear}
                        showAdvancedFilter={showAdvancedFilter}
                        handleAdvancedFilter={handleAdvancedFilter}
                    />
                </TabPanel> */}
                <TabPanel header={<div className="custom-tab">{getCountForMRN()} <span>Completed</span></div>} >
                    <FilterSection
                        item={item}
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        globalFilterValue={globalFilterValue}
                        onGlobalFilterChange={onGlobalFilterChange}
                        globalFilterOptions={globalFilterOptions}
                        setGlobalFilterOptions={setGlobalFilterOptions}
                        handleClear={handleClear}
                        showAdvancedFilter={showAdvancedFilter}
                        handleAdvancedFilter={handleAdvancedFilter}
                    />
                </TabPanel>
            </TabView>

            <div className={`card physician-table ${showAdvancedFilter ? "custom-padding" : "mt-0"}`}>
                <div className="table-responsive" style={{ overflowX: "auto" }}>
                    {renderDataTable()}
                </div>
            </div>
            <ViewDocumentModal />
        </div>
    );
}