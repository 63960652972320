import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../utility/api";

// Base URL for API requests


// Define the async thunk for fetching custom filters
export const GetCustomFilterList = createAsyncThunk(
  "advancedFilter/GetCustomFilterList",
  async ( updateData, apiThunk) => {
    try {
      const response = await api.post(`/api/ArManager/GetCustomFilterList`, updateData)
      return response.data
    } catch (error) {
      console.error("Error fetching patient data:", error);
      throw error;
    }
  }
  
);

// Initial state for custom filters
const initialState = {
  filters: [],
  loading: false,
  error: null,
};

// Create slice for fetching filters
const customFilterSlice = createSlice({
  name: "customFilter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCustomFilterList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCustomFilterList.fulfilled, (state, action) => {
        state.loading = false;
        state.filters = action.payload?.data;
      })
      .addCase(GetCustomFilterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });


  },
});

// Export the reducer for use in the store
export default customFilterSlice.reducer;
