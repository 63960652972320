import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authconfig";
import { PrimeReactProvider } from "primereact/api";
import { MsalProvider } from "@azure/msal-react";


import 'primeflex/primeflex.css';

import { registerLicense } from "@syncfusion/ej2-base";
import AuthProvider from "./ConfigLogin";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const LICENSE_KEY =
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlccXRdRGleU0B2Vko=";
registerLicense(LICENSE_KEY);

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <AuthProvider>
    <Provider store={store}>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>

    </Provider>
  </AuthProvider>

);
